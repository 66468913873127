import React, { useEffect, useState } from 'react'
import './style.less'
import { LoadBaiduMapScript } from '@/utils'

let map: any = null //地图

interface Point {
    longitude: number | null,
    latitude: number | null,
    name: string
    address: string
}


interface Proo {
    points: Point[],
    position: {
        longitude: number,
        latitude: number
    }
}

const BaiduMap: React.FC<Proo> = (props) => {
    const { points, position } = props
    console.log('===', points)
    const [current, setCurrent] = useState<Point>({
        longitude: null,
        latitude: null,
        name: '',
        address: ''
    })



    // useEffect(() => {
    //     return () => {
    //         if (map) {
    //             // map.destroy()
    //             map = null
    //         }
    //     }
    // }, [])


    useEffect(() => {
        if(points && points.length ) {
            LoadBaiduMapScript().then(() => {
                console.log('======>地图执行到1')
                //@ts-ignore
                if (points && points.length && window.BMapGL?.Map) {
                    console.log('======>地图执行到2')
                    setTimeout(() => {
                        console.log('======>才开始')
                        initMap()
                    }, 500)
                }
            })
        }
        return () => {
            if (map) {
                // map.destroy()
                map = null
            }
        }
    }, [position, points])


    const initMap = () => {
        //@ts-ignore
        console.log('======>', BMapGL, position, points)
        //@ts-ignore
        map = new window.BMapGL.Map('jx-baidu', {
            enableMapClick: false,
        })
        getLocation()
        map.enableScrollWheelZoom(true)
        //@ts-ignore
        var navi3DCtrl = new BMapGL.NavigationControl3D({
            //@ts-ignore
            offset: new BMapGL.Size(20, 20)
        });  // 添加3D控件
        map.addControl(navi3DCtrl);
        // 创建定位控件a
        //@ts-ignore
        var locationControl = new BMapGL.LocationControl({
            // 控件的停靠位置（可选，默认左上角）
            //@ts-ignore
            anchor: BMAP_ANCHOR_TOP_RIGHT,
            // 控件基于停靠位置的偏移量（可选）
            //@ts-ignore
            offset: new BMapGL.Size(25, 110)
        });
        // 将控件添加到地图上
        map.addControl(locationControl);
        //@ts-ignore
        let zoomCtrl = new BMapGL.ZoomControl({
            //@ts-ignore
            anchor: BMAP_ANCHOR_TOP_RIGHT,
            //@ts-ignore
            offset: new BMapGL.Size(33, 160)
        }
        ) // 添加缩放控件
        map.addControl(zoomCtrl)
        points.forEach(item => {
            //@ts-ignore
            let point = new BMapGL.Point(item.longitude, item.latitude)
            //@ts-ignore
            let marker = new BMapGL.Marker(point)
            marker.type = 'point'
            marker.disableMassClear()
            map.addOverlay(marker)
        })
        points.length && clickPlace(points[0])
    }

    const getLocation = () => {
        console.log('===定位')
        //@ts-ignore
        let point = new BMapGL.Point(position.longitude, position.latitude)
        map.centerAndZoom(point, 18);
    }

    const createDOM = (place: any) => {
        return () => {
            var div = document.createElement('div');
            div.style.zIndex = '99999';
            div.style.backgroundColor = '#E9623E';
            div.style.position = 'absolute'
            div.style.color = '#fff';
            div.style.height = '58px';
            div.style.padding = '0 20px';
            div.style.top = '-25px';
            div.style.left = '-3px';
            div.style.lineHeight = '58px';
            div.style.whiteSpace = 'nowrap';
            div.style.borderRadius = '10px';

            var title = document.createElement('div');
            title.style.display = 'block';
            title.style.fontSize = '16px';
            title.style.fontWeight = '600';
            title.style.fontFamily = 'PingFang SC';
            div.appendChild(title);
            //@ts-ignore
            title.appendChild(document.createTextNode(place.name));


            var arrow = document.createElement('div');
            arrow.style.position = 'absolute';
            arrow.style.top = '58px';
            arrow.style.left = '43%';
            arrow.style.width = '0';
            arrow.style.height = '0';
            arrow.style.borderColor = '#E9623E transparent transparent transparent';
            arrow.style.borderStyle = 'solid';
            arrow.style.borderWidth = '10px';
            arrow.style.overflow = 'hidden';
            div.appendChild(arrow);
            return div;
        }
    }


    // const LoadBaiduMapScript = () => {
    //     const BMap_URL = '//api.map.baidu.com/api?type=webgl&v=1.0&ak=fvXiyEhRupb0dUDsLPbDnc7Ixc7XpVif&callback=onBMapCallback'
    //     // @ts-ignore
    //     return new Promise((resolve) => {
    //         // 如果已加载直接返回
    //         //@ts-ignore
    //         if (typeof BMapGL !== 'undefined') {
    //             //@ts-ignore
    //             resolve(BMapGL)
    //             return true
    //         }
    //         // 百度地图异步加载回调处理
    //         // @ts-ignore
    //         window.onBMapCallback = function () {
    //             //@ts-ignore
    //             resolve(BMapGL)
    //         }
    //         // 插入script脚本
    //         let scriptNode1 = document.createElement('script')
    //         scriptNode1.setAttribute('type', 'text/javascript')
    //         scriptNode1.setAttribute('src', BMap_URL)
    //         document.body.appendChild(scriptNode1)
    //     })
    // }

    const clickPlace = (place: Point) => {
        setCurrent(place)
        let allOverlayList = map.getOverlays();
        for (var i = 0; i < allOverlayList.length; i++) {
            if (!allOverlayList[i].type) {
                map.removeOverlay(allOverlayList[i]);
            }
        }

        //@ts-ignore
        var customOverlay = new BMapGL.CustomOverlay(createDOM(place), {
            //@ts-ignore
            point: new BMapGL.Point(place.longitude, place.latitude),
            opacity: 0.5,
            offsetY: -10,
            properties: {
                title: place.name,
                text: place.address,
            }
        });
        map.addOverlay(customOverlay);
        //@ts-ignore
        let point = new BMapGL.Point(place.longitude, place.latitude)
        // let opt = {
        //     width: 200,
        //     // height:1,
        //     title: place.address,
        //     message: place.address
        // }
        // //@ts-ignore
        // let infoWindow = new BMapGL.InfoWindow(place.address, opt)
        // map.openInfoWindow(infoWindow, point)
        map.centerAndZoom(point, 18);
    }

    return (
        <div className='baidu-map'>
            <div id='jx-baidu' style={{ width: '100%', height: 400 }}></div>

            <div className='place_box'>
                {
                    points.map(item => {
                        return (
                            <div className={current.name === item.name ? 'place_item place_item_active' : 'place_item'} onClick={() => { clickPlace(item) }}>
                                <div className='place_name'>{item.name}</div>
                                <div className='place_desc'>{item.address}</div>
                            </div>
                        )
                    })
                }
            </div>

        </div>
    )
}

export default BaiduMap
