// 静态图片地址管理
// 头部58标题图片
export const header_logo = 'https://img.58cdn.com.cn/dist/jxt/images/jxtschool/logo.png?a=4'
// 定位标记图标
export const header_coordinate = 'https://img.58cdn.com.cn/dist/jxt/images/jxtschool/coordinate.png?a=1'
// 小三角图标
export const header_triangle = 'https://img.58cdn.com.cn/dist/jxt/images/jxtschool/triangle.png'
// 电话图标
export const phone = 'https://img.58cdn.com.cn/dist/jxt/images/jxtschool/phone.png?a=1'
// footer提示图标
export const footer_frame = 'https://img.58cdn.com.cn/dist/jxt/images/jxtschool/frame.png'
export const footer_frame_1 = 'https://img.58cdn.com.cn/dist/jxt/images/jxtschool/footer_frame_1.png'
export const footer_frame_2 = 'https://img.58cdn.com.cn/dist/jxt/images/jxtschool/footer_frame_2.png'
export const footer_frame_3 = 'https://img.58cdn.com.cn/dist/jxt/images/jxtschool/footer_frame_3.png'
export const footer_frame_4 = 'https://img.58cdn.com.cn/dist/jxt/images/jxtschool/footer_frame_4.png'
// footer警徽
export const footer_beian = 'https://img.58cdn.com.cn/dist/jxt/images/jxtschool/beian.png'
// 微信公众号二维码 
export const footer_wechat1 = 'https://img.58cdn.com.cn/dist/jxt/images/jxtschool/Wechat_code_gzh_img.jpg?v=1'
// 微信视频号二维码 
export const footer_wechat2 = 'https://img.58cdn.com.cn/dist/jxt/images/jxtschool/wechat_2.png'
// 抖音二维码 
export const footer_wechat3 = 'https://img.58cdn.com.cn/dist/jxt/images/jxtschool/wechat_3.png'
// 咨询微信二维码 
export const footer_wechat4 = 'https://img.58cdn.com.cn/dist/jxt/images/jxtschool/wecha_code_img.jpg'
// 右侧电话图标
export const right_phone1 = 'https://img.58cdn.com.cn/dist/jxt/images/jxtschool/right_phone1.png'
// 右侧在线咨询
export const right_robot = 'https://img.58cdn.com.cn/dist/jxt/images/jxtschool/right_robot.png'
// 右侧返回顶部 
export const right_scrolltop = 'https://img.58cdn.com.cn/dist/jxt/images/jxtschool/right_scrolltop.png'
// 右侧电话高亮图标
export const right_phone_2 = 'https://img.58cdn.com.cn/dist/jxt/images/jxtschool/right_phone_2.png'
// 右侧在线咨询高亮图标
export const right_robot_1 = 'https://img.58cdn.com.cn/dist/jxt/images/jxtschool/right_robot_1.png'
// 右侧返回顶部高亮图标
export const right_scrolltop_1 = 'https://img.58cdn.com.cn/dist/jxt/images/jxtschool/right_scrolltop_1.png'
// 查看更多后面的小箭头
export const rout_1 = 'https://img.58cdn.com.cn/dist/jxt/images/jxtschool/rout_1.png'
// 训练场默认图片
export const training_ground_img = 'https://img.58cdn.com.cn/dist/jxt/images/jxtschool/h5/train-default.png'
// 教练默认头像
export const user_img = 'https://img.58cdn.com.cn/dist/jxt/images/jxtschool/h5/coach-default.png'
// 班制默认背景图片
export const class_bg_1 = 'https://img.58cdn.com.cn/dist/jxt/images/jxtschool/h5/classes-default.png'
// 首页-服务图片
export const employ_img = 'https://img.58cdn.com.cn/dist/jxt/images/jxtschool/employ_img_1.png?a=9'
// 首页-智慧学车背景
export const wisdom_bg = 'https://img.58cdn.com.cn/dist/jxt/images/jxtschool/wisdom_bg.png'
// 首页-智慧学车banner图片1
export const wisdom_banner_1 = 'https://img.58cdn.com.cn/dist/jxt/images/jxtschool/wisdom_banner_1.png?v=1'
// 首页-智慧学车banner图片2
export const wisdom_banner_2 = 'https://img.58cdn.com.cn/dist/jxt/images/jxtschool/wisdom_banner_2.png?v=1'
// 首页-智慧学车banner图片3
export const wisdom_banner_3 = 'https://img.58cdn.com.cn/dist/jxt/images/jxtschool/wisdom_banner_3.png?v=1'
// 明星教练banner图片
export const banner_img_1 = 'https://img.58cdn.com.cn/dist/jxt/images/jxtschool/banner_img_11.png?v=4'
// 练车场地banner图片
export const banner_img_2 = `https://img.58cdn.com.cn/dist/jxt/images/jxtschool/banner_img_22.png?v=4`
// 班制banner图片
export const banner_img_3 = 'https://img.58cdn.com.cn/dist/jxt/images/jxtschool/banner_img_33.png?v=3'
// 报名须知banner图片
export const banner_img_4 = 'https://img.58cdn.com.cn/dist/jxt/images/jxtschool/banner_img_44.png?v=4'
// 驾校资讯banner图片
export const banner_img_5 = `https://img.58cdn.com.cn/dist/jxt/images/jxtschool/banner_img_55.png?v=4`
// 联系我们banner图片
export const banner_img_6 = 'https://img.58cdn.com.cn/dist/jxt/images/jxtschool/banner_img_66.png?v=5'
// 驾校资讯驾校新闻默认图片
export const tenant_info_img1 = 'https://img.58cdn.com.cn/dist/jxt/images/jxtschool/tenant_info_img1.png?v=2'
// 驾校资讯新闻公告默认图片
export const tenant_info_img2 = `https://img.58cdn.com.cn/dist/jxt/images/jxtschool/tenant_info_img2.png?v=2`
// 驾校资讯常见问题默认图片
export const tenant_info_img3 = 'https://img.58cdn.com.cn/dist/jxt/images/jxtschool/tenant_info_img3.png?v=1'
// 练车场地banner图片
export const banner_img_home_1 = `https://img.58cdn.com.cn/dist/jxt/images/jxtschool/home_jx_banner_15.png?v=130`
// 练车场地banner图片
export const banner_img_home_2 = `https://img.58cdn.com.cn/dist/jxt/images/jxtschool/home_jx_banner_16.png?a=5`
// 教练评价人员默认头像
export const user_head_default = `https://img.58cdn.com.cn/dist/jxt/images/jxtschool/user_head_default.png?v=3`
// 空数据图片
export const empty_img = `https://img.58cdn.com.cn/dist/jxt/images/jxtschool/empty_img.png?v=4`
// 我的订单默认头像
export const defaultHeadImage = 'https://img.58cdn.com.cn/dist/jxt/images/jxtschool/h5/default_head_img1.png?v=3'



