import React, { useMemo, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Breadcrumb } from 'antd'
import { removeToken } from '@/utils'
import { defaultHeadImage } from '@/imgs'
import { getOrderList } from '@/api/home'
import EmptyList from '@/components/emptyList'

import './style.less'

const Myorder: React.FC = () => {
    const navigate = useNavigate()
    // 当前路由路径
    const { pathname } = useLocation()
    // 获取驾校ID
    const id = useMemo(() => {
        const arr = pathname?.split('/').filter((item) => (item))
        return arr[arr.length - 1]?.split("?")[0]?.replace(/[^0-9]/g, '')
    }, [pathname])

    // 数据
    const [userInfo, setUserInfo] = useState<any>(null)

    useEffect(() => {
        getList()
    }, [])

    // 获取数据
    const getList = async () => {
        try {
            const res: any = await getOrderList({})
            console.log(res, '======>')
            // 4100 登陆失效， 重新登陆
            if (res?.code === 4100) {
                removeToken('openCookieid')
                navigate(`/home/${id}`, {
                    state: {
                        login: 1
                    }
                })
            } else {
                setUserInfo(res.data)
            }
        } catch (e) {

        }
    }

    return (
        <div className="my-order">
            <div className="my-order-top">
                <div className="my-order-content">
                    <Breadcrumb
                        items={[
                            {
                                title: <div
                                    className="breadcrumb-btn"
                                    onClick={() => {
                                        window.scrollTo(0, 0)
                                        navigate(`/home/${id}`)
                                    }}>首页</div>,
                            },
                            {
                                type: 'separator',
                                separator: <span>//</span>,
                            },
                            {
                                title: '我的订单',
                            },
                        ]}
                        separator=''
                    />
                </div>
            </div>
            <div className="my-order-content">
                <div className="my-order-left">
                    <div className="my-order-user">
                        <div className="my-order-user-bg">
                            <div className="user-img"><img src={userInfo?.headImg ? userInfo?.headImg + '?w=107&h=72&ss=1' : defaultHeadImage} /></div>
                            <div className="user-name">HI，{userInfo?.name ? userInfo?.name : userInfo?.phone}</div>
                            {
                                !!userInfo?.name &&
                                <div className="user-phone">{userInfo?.phone}</div>
                            }
                        </div>
                    </div>
                    <div className="my-order-title">
                        我的订单
                    </div>
                </div>
                <div style={{ flex: 'none' }}>
                    <div className="my-order-right">
                        <div className="order-title">我的订单</div>
                        <div>
                            {
                                userInfo?.orders?.map((item: any, index: any) => {
                                    return (
                                        <div key={index} className="order-list-item">
                                            <div className="order-item-left">
                                                <div className="order-item-title">
                                                    <div className="order-item-cartype">{item?.carType}</div>
                                                    <div className="order-item-class">{item?.className}</div>
                                                </div>
                                                <div className="order-item-name">{item?.schName}</div>
                                            </div>
                                            <div className="order-item-right"><span style={{ fontSize: '20px', fontWeight: 'initial' }}>¥</span>{item?.actualFee}</div>
                                        </div>
                                    )
                                })
                            }
                            {!userInfo?.orders?.length &&
                                <EmptyList />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Myorder
