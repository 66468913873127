import React, { useEffect, useState } from "react";
import { banner_img_6, footer_wechat4 } from '@/imgs'
import { getPhone } from '@/utils'
import BaiduMap from "./baiduMap";
import { getSearch } from '@/api/home'
import './style.less'
import { getList } from "@/api/trainCar";

interface Contact {
    // jxName: string,
    contactUser: string,
    phone: string,
    wechat: string,
}
interface Point {
    longitude: number,
    latitude: number,
    name: string,
    address: string
}

interface Po {
    longitude: number,
    latitude: number
}

const ContactUs: React.FC = () => {
    const [contactInfo, setContactInfo] = useState<Contact>({
        // jxName: '58驾培集团',
        contactUser: '58驾培集团',
        phone: '189 0281 2402',
        wechat: footer_wechat4,
    })
    const [points, setPoints] = useState<Point[]>([])

    const [curPositon, setPosition] = useState<Po>({
        longitude: 116.404064,
        latitude: 39.923042
    })

    useEffect(() => {
        getData()
    }, [])

    const getData = async () => {
        let res: any = await Promise.all([
            getSearch(),
            getList({ isHome: false, pageSize: 5, pageIndex: 1 })
        ])
        console.log('----', res)
        if (res[0].code === 0) {
            setPosition({
                longitude: Number(res[0].data?.longitude),
                latitude: Number(res[0].data?.latitude)
            })
            setContactInfo({
                contactUser: res[0]?.data?.contact,
                phone: getPhone(res[0]?.data?.contactPhone),
                wechat: res[0]?.data?.wxQrCode,
            })
            // setContact({
            //     jxName: res[0].data?.name,
            //     contactUser: res[0].data?.name,
            //     phone: res[0].data?.applicationMobile,
            //     wechat:res[0].data?.keFuUrl ,
            // })
        }
        if (res[1].code === 0) {
            setPoints(res[1].data?.list || [])
        }
    }



    return <div className="contactus-shell">
        <div className="contactus-car-bg"> <img src={banner_img_6} alt="" /></div>
        <div className="contactus-container">
            <div className="contact-title">联系我们</div>
            <div className="contact-box">
                <div className="contact-wechat">
                    <div className="box">
                        <div className="username">{contactInfo.contactUser}</div>
                        <div className="phone">{contactInfo.phone}</div>
                        <div className="wechat">
                            <img src={contactInfo.wechat} />
                        </div>
                        <div className="scanf">用微信扫我咨询</div>
                    </div>
                </div>
                <div className="contact-map">
                    <BaiduMap points={points} position={curPositon} />
                </div>
            </div>

        </div>
    </div>
}

export default ContactUs