import React, { useMemo, useState } from 'react'
import { Popover } from 'antd'
import { useNavigate, useLocation } from "react-router-dom";
import { navList, addressList } from '../base'
import { syncTrackLog } from '@/api/home'
import { header_logo, header_coordinate, header_triangle, phone } from '@/imgs'
import { getApplicationMobile } from '@/utils'
import './style.less'

interface Props {
    schInfo: any // 驾校信息
    isCoach: boolean // 是否有明星教练
    orderBtnFn: any // 我的订单点击事件
}


const MyHeader: React.FC<Props> = (props) => {
    const { schInfo, isCoach, orderBtnFn } = props
    const navigate = useNavigate()
    // 当前路由路径
    const { pathname } = useLocation()
    const [open, setOpen] = useState(false);
    // 获取驾校ID
    const id = useMemo(() => {
        const arr = pathname?.split('/').filter((item) => (item))
        return arr[arr.length - 1]?.split("?")[0]?.replace(/[^0-9]/g, '')
    }, [pathname])
    // 展示的导航
    const showNavList = useMemo(() => {
        if (isCoach) {
            return navList
        }
        return navList.filter((item) => (item.name !== '明星教练'))
    }, [isCoach])

    // 切换地址
    const goAddress = (item: { name: string, id: string }) => {
        navigate('/home' + `/${item.id}`)
        setOpen(false);
    }

    const handleOpenChange = (newOpen: boolean) => {
        setOpen(newOpen);
    };

    // 地址下拉
    const addressRender = () => {
        return (
            <div className='address-popover'>
                {
                    addressList?.map((item, index) => (
                        <div className={`address-popover-item ${id === item.id && 'address-popover-item-active'}`} key={index} onClick={() => goAddress(item)}>{item.name}</div>
                    ))
                }
            </div>
        )
    }

    return (
        <>
            <div className='header-top'>
                <div className='header-top-left'>
                    <div className='header-logo'><img src={header_logo} /></div>
                    <Popover
                        content={addressRender}
                        trigger="click"
                        overlayStyle={{zIndex: 99999}}
                        open={open}
                        onOpenChange={handleOpenChange}
                    >
                        <div className='header-coordinate-wraaper'>
                            <img className='header-coordinate' src={header_coordinate} alt='' />
                            <div className='header-coordinate-name'>{schInfo?.city}</div>
                            <img className={`header-triangle ${open && 'header-triangle-active'}`} src={header_triangle} alt='' />
                        </div>
                    </Popover>
                </div>
                <div className='header-top-right'>
                    <img className='phone-icon' src={phone} alt='' />
                    <div className='header-phone'>{
                        // getApplicationMobile('18902812402')
                        getApplicationMobile(schInfo?.contactPhone || '')
                        
                    }</div>
                    <div
                        className='my-order-btn'
                        onClick={orderBtnFn}
                    >
                        <i className='jxt jxt-wodedingdan' style={{marginRight: '4px'}}/>
                        <span>我的订单</span>
                    </div>
                </div>
            </div>
            <div className='header-navs'>
                {
                    showNavList.map((item) => {
                        return (
                            <div
                                key={item.path}
                                className={pathname.includes(item.path) ? 'header-nav header-nav-active' : 'header-nav'}
                                onClick={() => {
                                    syncTrackLog({
                                        appId: "4",
                                        trackId: "jxtjp_gw_click",
                                        clientInfo: navigator.userAgent,
                                        tenantId: id,
                                        attrMap: {
                                            attr2: item.path + `/${id}`,
                                            attr1: '头部导航'
                                        }
                                    })
                                    navigate(item.path + `/${id}`)
                                }}
                            >{item.name}</div>
                        )
                    })
                }
            </div>
        </>
    )
}

export default MyHeader
