import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TitleShell from '@/components/titleShell'
import ClassItem from '@/components/classItem'
import EmptyList from '@/components/emptyList'
import { getOutList, syncTrackLog } from '@/api/home'
import './style.less'

interface Props {
    schoolId: string // 驾校ID
}

const ClassIntro: React.FC<Props> = (props) => {
    const { schoolId } = props
    const navigate = useNavigate()
    const [data, setData] = useState({
        list: [],
        pagination: {
            pageIndex: 1,
            pageSize: 4,
            totalPages: 1,
            totalRows: 2,
        }
    })

    useEffect(() => {
        getData()
    }, [schoolId])

    // 获取数据
    const getData = async () => {
        try {
            const res: any = await getOutList({ pageIndex: 1, pageSize: 4 })
            setData(res?.data)
        } catch (e) {

        }
    }

    // 跳转详情
    const goDetail = (value: any) => {
        syncTrackLog({
            appId: "4",
            trackId: "jxtjp_gw_click",
            tenantId: schoolId,
            clientInfo: navigator.userAgent,
            attrMap: {
                attr2: `/classBriefDetail/${schoolId}?id=${value?.id}`,
                attr1: '首页班型介绍-详情'
            }
        })
        window.open(`/classBriefDetail/${schoolId}?id=${value?.id}`)
    }

    return (
        <div className="class-intro">
            <TitleShell title="班型介绍" text="多样化班型 量身定制" url={`/classBrief/${schoolId}`} />
            <div className="class-intro-content">
                {
                    data?.list?.map((item, index) => (
                        <div key={index} onClick={(e) => {
                            e.stopPropagation()
                            goDetail(item)
                        }}>
                            <ClassItem data={item} />
                        </div>

                    ))
                }
                {
                    !data?.list?.length &&
                    <EmptyList />
                }
            </div>
        </div>
    )
}

export default ClassIntro
