import React, { useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { syncTrackLog } from '@/api/home'
import { openZhiChi } from '@/utils'
import { navList } from '../base'
import { getApplicationMobile } from '@/utils'
import { footer_frame, footer_frame_1, footer_frame_2, footer_frame_3, footer_frame_4, footer_beian, header_logo, footer_wechat1, footer_wechat2, footer_wechat3, footer_wechat4 } from '@/imgs'
import './style.less'

interface Props {
    schInfo: any // 驾校信息
}

const MyFooter: React.FC<Props> = ({ schInfo }) => {
    const navigate = useNavigate()
    // 当前路由路径
    const { pathname } = useLocation()
    // 获取驾校ID
    const id = useMemo(() => {
        const arr = pathname?.split('/')?.filter((item) => (item))
        return arr[arr.length - 1]?.split("?")[0]?.replace(/[^0-9]/g, '')
    }, [pathname])

    //路由跳转
    const goNav = (name: string) => {
        const item: any = navList?.find((item) => (item.name === name))
        if (item) {
            window.scrollTo(0, 0)
            syncTrackLog({
                appId: "4",
                trackId: "jxtjp_gw_click",
                clientInfo: navigator.userAgent,
                tenantId: id,
                attrMap: {
                    attr2: pathname,
                    attr1: '底部链接'
                }
            })
            navigate(item.path + `/${id}`)
        }
    }

    // 立即质询
    const goConsult = () => {
        syncTrackLog({
            appId: "4",
            trackId: "jxtjp_gw_click",
            clientInfo: navigator.userAgent,
            tenantId: id,
            attrMap: {
                attr2: pathname,
                attr1: '底部-立即咨询'
            }
        })
        openZhiChi()
    }

    return (
        <>
            <div className="footer-top">
                <div className="footer-top-warrper">
                    <div className="footer-top-item">
                        <img className="footer-frame" src={footer_frame} />
                        <div className="footer-frame-name">智慧驾校</div>
                    </div>
                    <div className="footer-top-item">
                        <img className="footer-frame" src={footer_frame_1} />
                        <div className="footer-frame-name">一对一专属班主任</div>
                    </div>
                    <div className="footer-top-item">
                        <img className="footer-frame" src={footer_frame_2} />
                        <div className="footer-frame-name">专业练车场地</div>
                    </div>
                    <div className="footer-top-item">
                        <img className="footer-frame" src={footer_frame_3} />
                        <div className="footer-frame-name">明星教练团队</div>
                    </div>
                    <div className="footer-top-item">
                        <img className="footer-frame" src={footer_frame_4} />
                        <div className="footer-frame-name">实力保障</div>
                    </div>
                </div>
            </div>
            <div className="footer-container">
                <div className="footer-container-warrper">
                    <div className="footer-container-left">
                        <div className="footer-container-logoimg">
                            <img src={header_logo} />
                        </div>
                        <div className="footer-container-navs">
                            <div>
                                <div className="footer-container-title">服务介绍</div>
                                <div className="footer-container-nav" onClick={() => goNav('首页')}>智慧驾校</div>
                                <div className="footer-container-nav" onClick={() => goNav('练车场地')}>专业练车场地</div>
                                <div className="footer-container-nav" onClick={() => goNav('班型介绍')}>班型介绍</div>
                                <div className="footer-container-nav" onClick={() => goNav('明星教练')}>明星教练</div>
                                <div className="footer-container-nav" onClick={() => goNav('报名须知')}>报名须知</div>
                            </div>
                            <div className="footer-container-navs-right">
                                <div className="footer-container-title">公司介绍</div>
                                <div className="footer-container-nav" onClick={() => goNav('驾校资讯')}>公司新闻</div>
                                <div className="footer-container-nav" onClick={() => goNav('驾校资讯')}>行业动态</div>
                                <div className="footer-container-nav" onClick={() => goNav('联系我们')}>联系我们</div>
                            </div>
                        </div>

                    </div>
                    <div className="footer-container-right">
                        <div className="footer-phone-name">58驾培学车热线</div>
                        <div className="footer-phone-content">
                            <div className="footer-phone">{
                                // getApplicationMobile('18902812402')
                                getApplicationMobile(schInfo?.contactPhone || '')
                            }</div>
                            <div className="footer-phone-btn" onClick={goConsult}>报名咨询</div>
                        </div>
                        <div className="footer-wechats">
                            <div className="footer-wechats-item">
                                <img className="footer-wechat-img" src={footer_wechat1} />
                                <div className="footer-wechat-text">
                                    <div>微信扫一扫</div>
                                    <div>关注公众号</div>
                                </div>
                            </div>
                            <div className="footer-wechats-item">
                                <img className="footer-wechat-img" src={footer_wechat2} />
                                <div className="footer-wechat-text">
                                    <div>微信扫一扫</div>
                                    <div>关注视频号</div>
                                </div>
                            </div>
                            <div className="footer-wechats-item">
                                <img className="footer-wechat-img" src={footer_wechat3} />
                                <div className="footer-wechat-text">
                                    <div>抖音搜索页扫一扫</div>
                                    <div>关注抖音号</div>
                                </div>
                            </div>
                            <div className="footer-wechats-item">
                                <img className="footer-wechat-img" src={schInfo?.wxQrCode} />
                                <div className="footer-wechat-text">
                                    <div>快用微信</div>
                                    <div>扫我咨询吧!</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="footer-bottom-warrper">
                    <a className="footer_beian_img" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=12011602000841" target="_blank">
                        <img className="footer-beian" src={footer_beian} />
                        <span className="footer-bottom-text" style={{ marginLeft: '8px' }}>津公网安备 12011602000841号</span>
                    </a>
                    {/* <span className="footer-bottom-text">杭州联桥网络科技有限公司</span> */}
                    <a href="https://beian.miit.gov.cn" target="_blank">
                        <span className="footer-bottom-text">津ICP备18006324号-1</span>
                    </a>
                    <a href="https://j1.58cdn.com.cn/dist/jxedt/permit/license.html" target="_blank">
                        {/* <span className="footer-bottom-text">Copyright © 天津五八驾考信息技术有限公司2011版权所有</span> */}
                        <span className="footer-bottom-text">天津五八驾考信息技术有限公司</span>
                    </a>
                    <span className="footer-bottom-text" style={{ marginRight: '0' }}>58赶集集团旗下网站</span>
                </div>
            </div>
        </>
    )
}

export default MyFooter
