import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { class_bg_1 } from '@/imgs'
import { openZhiChi } from '@/utils'
import { syncTrackLog } from '@/api/home'
import './style.less'

interface Props {
    data: any
}

const ClassBriefItem: React.FC<Props> = (props) => {
    const { data } = props
    // 当前路由路径
    const { pathname } = useLocation()
    // 获取驾校ID
    const id = useMemo(() => {
        const arr = pathname?.split('/').filter((item) => (item))
        const ydtCode = arr[arr.length - 1]?.split("?")[0]?.replace(/[^0-9]/g, '')
        return ydtCode ? ydtCode : '1707325546549014529'
    }, [pathname])

    // 立即质询
    const goConsult = (e:any) => {
        e.stopPropagation()
        syncTrackLog({
            appId: "4",
            trackId: "jxtjp_gw_click",
            clientInfo: navigator.userAgent,
            tenantId: id,
            attrMap: {
                attr2: pathname,
                attr1: '班型介绍-报名咨询'
            }
        })
        openZhiChi()
    }
    return (
        <div className="class-brief-item">
            <div className="class-item-head-img">
                <img src={data.picUrl ? data.picUrl + '?w=195&h=146' : class_bg_1} />
            </div>
            <div className="class-item-info">
                <div className="class-info-name">
                    {data.dicTrainType} {data.name}
                </div>
                <div className="class-info-tabs">
                    <div className="class-info-tab">
                        {
                            !!data?.naBen &&
                            <div className="class-tab-item">最快{data?.naBen}天拿证</div>
                        }
                        {
                            !!data?.ctdK2 &&
                            <div className="class-tab-item" style={{ marginLeft: '6px' }}>{data?.ctdK2}</div>
                        }
                        {
                            !!data?.learnDriverTime &&
                            <div className="class-tab-item" style={{ marginLeft: '6px' }}>{data?.learnDriverTime}</div>
                        }
                    </div>
                </div>
                <div className="class-info-remarks">
                    {
                        data?.remarksList?.map((item: string, index: number) => (
                            <div className="class-remark-item" key={index}>{item}</div>
                        ))
                    }
                </div>
            </div>
            <div className="class-item-right">
                <div className="class-info-money">
                    <span style={{ fontSize: '14px', color: '#999999', textDecoration: 'line-through', fontWeight: 'initial', marginRight: '10px' }}>原价¥{data?.originalPrice || '0'}</span>
                    <span style={{ fontSize: '14px', fontWeight: 'initial' }}>活动价</span>
                    ¥{data?.price}
                </div>
                <div
                    className="class-info-btn"
                    onClick={goConsult}
                >报名咨询</div>
            </div>
        </div>
    )
}

export default ClassBriefItem
