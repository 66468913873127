import React from 'react';
import ReactDOM from 'react-dom/client';
import "normalize.css"
import '@/index.css';
import reportWebVitals from '@/reportWebVitals';
import MyLayout from '@/layout'
import Nothave from '@/pages/nothave'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'swiper/css';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<Routes>
				<Route path={'/nothave'} element={<Nothave />} />
				<Route path={'/*'} element={<MyLayout />} />
			</Routes>
		</BrowserRouter>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
