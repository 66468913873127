import React from 'react'
import { empty_img } from '@/imgs/index'
import './style.less'

const EmptyList: React.FC = () => {
    return (
        <div className='empty-list'>
            <div className='empty-list-content'>
                <img src={empty_img} alt="" />
                <span className='empty-content-text'>暂无数据</span>
            </div>
        </div>
    )
}

export default EmptyList

