import React from "react";
import { address_1, car_type_1 } from '@/utils/base64'
import { training_ground_img } from '@/imgs'
import './style.less'

interface Props {
    data: any
}

const TrainingGround: React.FC<Props> = (props) => {
    const { data } = props
    return (
        <div className="training-cround">
            <div className="training-cround-img">
                <img src={(data?.jxCdPicUrl?.length && data?.jxCdPicUrl[0]) ? data?.jxCdPicUrl[0] + '?w=285&h=160' : training_ground_img} />
            </div>
            <div className="training-cround-title">{data.name}</div>
            <div className="training-cround-cartype">
                <img src={car_type_1} />
                <div className="training-cartype-text">{data.vehicleType?.split(',')?.join('/')}</div>
            </div>
            <div className="training-cround-address">
                <img src={address_1} />
                <div className="training-address-text">{data.address}</div>
            </div>
        </div>
    )
}

export default TrainingGround
