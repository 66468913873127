import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { banner_img_5 } from '@/imgs'
import { getText } from '@/utils'
import { getNews } from '@/api/jxNews'
import MyPagination from '@/components/pagination'
import './style.less'
import { navs, imgUrls } from './config'
import { syncTrackLog } from "@/api/home";
import EmptyList from "@/components/emptyList";


const TenantInfo: React.FC = () => {
    const navigate = useNavigate()
    const [key, setKey] = useState<string>('0')
    const [data, setData] = useState<any>(
        {
            list: [],
            pagination: {
                pageIndex: 1,
                pageSize: 8,
                totalPages: 1,
                totalRows: 2,
            }
        }
    )

    const { pathname } = useLocation()
    // 获取驾校ID
    const id = useMemo(() => {
        const arr = pathname?.split('/').filter((item) => (item))
        return arr[arr.length - 1]?.split("?")[0]?.replace(/[^0-9]/g, '')
    }, [pathname])

    useEffect(() => {
        getData()
    }, [key, id])


    const getData = async (pageInfo = {}) => {
        try {
            let res: any = await getNews({ ...data.pagination, ...pageInfo, type: key })
            setData(res.data)
            console.log(res)
        } catch (error) {

        }
    }

    const toDetail = (item: any) => {
        if (Number(key) === 0 && item.linkType === 1) {
            window.open(item.text)
            return
        }
        syncTrackLog({
            appId: "4",
            trackId: "jxtjp_gw_click",
            clientInfo: navigator.userAgent,
            tenantId: id,
            attrMap: {
                attr1: `/tenantInfoDetail/${id}?id=${item.id}&type=${key}`,
                attr2: "驾校资讯-详情"
            }
        })
        navigate(`/tenantInfoDetail/${id}?id=${item.id}&type=${key}`)
    }
    // 改变分页
    const changePage = (pageIndex: number) => {
        console.log('---')
        syncTrackLog({
            appId: "4",
            trackId: "jxtjp_gw_click",
            clientInfo: navigator.userAgent,
            tenantId: id,
            attrMap: {
                attr1: pathname,
                attr2: "驾校资讯-分页按钮"
            }
        })
        getData({ pageIndex: pageIndex })
    }

    return (
        <div className="jx-news">
            <div className="jx-news-bg"> <img src={banner_img_5} alt="" /></div>
            <div className="jx-news-box">
                <div className="jx-news-tab">
                    {
                        navs.map((item) => {
                            return (
                                <div
                                    key={item.key}
                                    className={key === item.key ? 'header-nav header-nav-active' : 'header-nav'}
                                    onClick={() => {
                                        setKey(item.key)
                                        setData({
                                            list: [],
                                            pagination: {
                                                pageIndex: 1,
                                                pageSize: 8,
                                                totalPages: 1,
                                                totalRows: 2,
                                            }
                                        })
                                        syncTrackLog({
                                            appId: "4",
                                            trackId: "jxtjp_gw_click",
                                            clientInfo: navigator.userAgent,
                                            tenantId: id,
                                            attrMap: {
                                                attr1: pathname,
                                                attr2: "驾校资讯-tab按钮"
                                            }
                                        })
                                    }}
                                >{item.name}</div>
                            )
                        })
                    }
                </div>
                <div className="jx-news-list">

                    {
                        data.list.length ?
                            data.list.map((item: any) => {
                                return (
                                    <div className="jx-new-item" key={item.id} onClick={() => {
                                        toDetail(item)
                                    }}>
                                        <div className="new-img">
                                            <img src={item.picUrl ? item.picUrl + '?w=198&h=112' : imgUrls[key]} />
                                        </div>
                                        <div className="new-box">
                                            <div className="new-header">
                                                <div className="new-title" >
                                                    <div className="text">{item.title}</div>
                                                    {!!item.top && <div className="top">置顶</div>}
                                                </div>
                                                <div className="new-date">{item.dateShow}</div>
                                            </div>
                                            <div className="new-text">{getText(item.text || '')}</div>
                                        </div>
                                    </div>
                                )
                            })
                            : <EmptyList />
                    }
                </div>
                <div className="jx-news-pagination">
                    <MyPagination
                        total={data?.pagination?.totalRows}
                        pageSize={data?.pagination?.pageSize}
                        pageIndex={data?.pagination?.pageIndex}
                        changePage={changePage}
                    />
                </div>
            </div>
        </div>
    )
}

export default TenantInfo