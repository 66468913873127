import React from 'react'
import { Pagination } from 'antd'
import './style.less'

interface Props {
    total: number // 总共多少页
    pageIndex: number // 当前页
    pageSize: number // 每页条数
    changePage: (pageIndex: number) => void // 分页改变调用的方法
}

const MyPagination: React.FC<Props> = (props) => {
    const { total, pageIndex, pageSize, changePage } = props

    return (
        <Pagination
            className='my-pagination'
            current={pageIndex}
            pageSize={pageSize}
            total={total}
            showSizeChanger={false}
            hideOnSinglePage={true}
            onChange={(page) => changePage(page)}
            itemRender={(_, type, originalElement) => {
                if (type === 'prev') {
                    return <div
                        className='my-pagination-prve'
                    >上一页</div>
                }
                if (type === 'next') {
                    return <div
                        className='my-pagination-next'
                    >下一页</div>
                }
                if (type === 'page') {
                    return <div
                        className='my-pagination-page'
                    >{_}</div>
                }
                return originalElement
            }}
        />
    )
}

export default MyPagination
