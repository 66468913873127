import React from 'react'
import './style.less'

const Nothave: React.FC = () => {
    return (
        <div className='no-thave'>
            <div className='no-thave-img'><img src='https://j1.58cdn.com.cn/dist/jledt/comm/error.png'/></div>
            <div className='no-thave-text'>很抱歉，未找到驾校信息</div>
            <a href='https://www.jxedt.com' className='no-thave-btn'>返回首页</a>
        </div>
    )
}

export default Nothave
