import React from "react";
import { EffectFade, Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { banner_img_home_1, banner_img_home_2 } from '@/imgs'
import { openZhiChi } from '@/utils/index'
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './style.less';

const Banner: React.FC = () => {

    const gozc = () => {
        openZhiChi()
    }

    return (
        <div className="mySwiper">
            {/* <div className="aaa" style={{height:'435px'}}>

            </div> */}
            <Swiper
                spaceBetween={30}
                effect={'fade'}
                autoplay={{ delay: 3000, pauseOnMouseEnter: true }}
                loop={true}
                pagination={{
                    clickable: true,
                }}
                modules={[EffectFade, Autoplay, Pagination]}
                className="mySwiper"
            >
                <SwiperSlide className="swiper-slide-item swiper-slide-item-1">
                    <img src={banner_img_home_1} alt="" onClick={gozc} />
                </SwiperSlide>
                <SwiperSlide className="swiper-slide-item swiper-slide-item-2" >
                    <img src={banner_img_home_2} alt="" onClick={gozc} />
                </SwiperSlide>
                {/* <SwiperSlide className="swiper-slide-item">
                    <img src={banner_img_home_1} alt="" onClick={gozc} />
                </SwiperSlide>
                <SwiperSlide className="swiper-slide-item">
                    <img src={banner_img_home_1} alt="" onClick={gozc} />
                </SwiperSlide> */}
            </Swiper>
        </div>
    )
}

export default Banner
