import React, { useState, useEffect } from "react";
import TitleShell from '@/components/titleShell'
import CoachItem from '@/components/coachItem'
import EmptyList from '@/components/emptyList'
import { getCoachList, syncTrackLog } from '@/api/home'
import { useNavigate } from "react-router-dom";
import './style.less'

interface Props {
    schoolId: string // 驾校ID
}

const Coach: React.FC<Props> = (props) => {
    const { schoolId } = props
    const navigate = useNavigate()
    const [data, setData] = useState({
        list: [],
        pagination: {
            pageIndex: 1,
            pageSize: 4,
            totalPages: 1,
            totalRows: 2,
        }
    })

    useEffect(() => {
        getData()
    }, [schoolId])

    // 获取数据
    const getData = async () => {
        try {
            const res: any = await getCoachList({ isHome: true, pageIndex: 1, pageSize: 6 })
            setData(res?.data)
        } catch (e) {

        }
    }
    // 跳转详情
    const goDetail = (value: any) => {
        syncTrackLog({
            appId: "4",
            trackId: "jxtjp_gw_click",
            clientInfo: navigator.userAgent,
            tenantId: schoolId,
            attrMap: {
                attr2: `/starCoachDetail/${schoolId}?id=${value?.id}`,
                attr1: '首页明星教练-详情'
            }
        })
        // window.scrollTo(0, 0)
        window.open(`/starCoachDetail/${schoolId}?id=${value?.id}`)
        // navigate(`/starCoachDetail/${schoolId}?id=${value?.id}`)
    }
    return (
        <>
            {
                !!data?.pagination.totalRows ?
                    <div className="coach-shell">
                        <TitleShell title="明星教练" text="金牌资深教练一对一教学" url={`/starCoach/${schoolId}`} />
                        <div className="coach-content">
                            {
                                data?.list?.map((item, index) => (
                                    <div
                                        key={index}
                                        style={{ marginRight: ((index + 1) % 3) === 0 ? '' : '19px' }}
                                        onClick={() => (goDetail(item))}
                                    >
                                        <CoachItem data={item} />
                                    </div>
                                ))
                            }
                            {
                                !data?.list?.length &&
                                <EmptyList />
                            }
                        </div>
                    </div>:
                    null
            }
        </>
    )
}

export default Coach
