import React, { useMemo, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Breadcrumb } from 'antd'
import { user_img } from '@/imgs'
import { openZhiChi } from '@/utils'
import { realname, coach_dingwei, coach_train } from '@/utils/base64'
import { getCoachDetail, getCoachDpList, syncTrackLog } from '@/api/home'
import IntroduceItem from '@/components/introduceItem'
import MyPagination from '@/components/pagination'
import EmptyList from '@/components/emptyList'
import './style.less'

const StarCoachDetail: React.FC = () => {
    const navigate = useNavigate()
    // 当前路由路径
    const { pathname, search } = useLocation()
    // 获取驾校ID
    const id = useMemo(() => {
        const arr = pathname?.split('/')?.filter((item) => (item))
        return arr[arr.length - 1]?.split("?")[0]?.replace(/[^0-9]/g, '')
    }, [pathname])
    // 教练ID
    const coachId = useMemo(() => {
        const arr = search?.split('id=')
        return arr[arr.length - 1]
    }, [search])
    // 教练数据
    const [data, setData] = useState<any>(null)
    // 评论数据
    const [dpList, setDpList] = useState({
        list: [],
        pagination: {
            pageIndex: 1,
            pageSize: 12,
            totalPages: 1,
            totalRows: 1,
        }
    })

    // 获取列表数据
    useEffect(() => {
        if (coachId) {
            getData()
            getDpList({ pageIndex: 1 })
        }
    }, [coachId])
    // 教练数据
    const getData = async () => {
        try {
            const res: any = await getCoachDetail({ id: coachId })
            setData(res?.data)
        } catch (e) {

        }
    }
    // 评价数据
    const getDpList = async (page: any) => {
        try {
            const res: any = await getCoachDpList({ isHome: false, id: coachId, schoolId: id, ...page, pageSize: 12 })
            setDpList(res?.data)
        } catch (e) {

        }
    }
    // 改变分页
    const changePage = (pageIndex: number) => {
        syncTrackLog({
            appId: "4",
            trackId: "jxtjp_gw_click",
            clientInfo: navigator.userAgent,
            tenantId: id,
            attrMap: {
                attr2: pathname,
                attr1: '明星教练详情评论-分页按钮'
            }
        })
        getDpList({ pageIndex: pageIndex })
    }

    // 立即质询
    const goConsult = () => {
        syncTrackLog({
            appId: "4",
            trackId: "jxtjp_gw_click",
            clientInfo: navigator.userAgent,
            tenantId: id,
            attrMap: {
                attr2: pathname,
                attr1: '明星教练详情-立即咨询'
            }
        })
        openZhiChi()
    }

    return (
        <div className="star-coach-detail">
            <div className="train-car-detail-top">
                <div className="train-detail-top-content">
                    <Breadcrumb
                        items={[
                            {
                                title: <div
                                    className="breadcrumb-btn"
                                    onClick={() => {
                                        window.scrollTo(0, 0)
                                        navigate(`/home/${id}`)
                                    }}
                                >首页</div>,
                            },
                            {
                                type: 'separator',
                                separator: <span>//</span>,
                            },
                            {
                                title: <div
                                    className="breadcrumb-btn"
                                    onClick={() => {
                                        window.scrollTo(0, 0)
                                        navigate(`/starCoach/${id}`)
                                    }}>明星教练</div>,
                            },
                            {
                                type: 'separator',
                                separator: <span>//</span>,
                            },
                            {
                                title: data?.coachName,
                            },
                        ]}
                        separator=''
                    />
                </div>
            </div>
            <div className="train-car-detail-content">
                <div className="train-car-detail-shell">
                    <div className="coach-info">
                        <div className="coach-info-top">
                            <div className="coach-info-top-img"><img src={data?.coachPhotoUrl ? data?.coachPhotoUrl + '?w=197&h=197' : user_img} alt="" /></div>
                            <div className="coach-info-top-right">
                                <div className="info-right-top">
                                    <div className="info-top-content">
                                        <div className="info-top-title">
                                            <div className="coach-info-name">
                                                {data?.coachName}
                                                <div className="coach-info-realname"><img src={realname} /></div>
                                                <div className="coach-info-duration">{data?.coachYear || 1}年教龄</div>
                                            </div>
                                        </div>
                                        {
                                            !!data?.coachAreaList?.length &&
                                            <div className="info-top-vehicle">
                                                <img src={coach_train} alt="" />
                                                <div className="coach-info-cartype-text">{data?.coachAreaList?.join('、')}</div>
                                            </div>
                                        }
                                    </div>
                                    <div className="info-right-top-btn" onClick={goConsult}>报名咨询</div>
                                </div>
                                <div className="info-contacts">
                                    <img src={coach_dingwei} alt="" />
                                    <div className="coach-info-evaluate-text">
                                        服务<span className="coach-info-text-number" style={{ marginRight: '10px' }}>{data?.score}</span>
                                        点评<span className="coach-info-text-number">{data?.dpCount}</span>条
                                    </div>
                                </div>
                                <div className="info-address">
                                    {
                                        data?.dpInfo?.map((item: string, index: number) => {
                                            return (
                                                <div className="coach-tab-item" style={{ marginLeft: index !== 0 ? '6px' : '' }} key={index}>{item}</div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        {
                            !!data?.coachDesc &&
                            <div className="coach-info-introduce">
                                <div>{data?.coachDesc}</div>
                            </div>
                        }
                    </div>
                    <div className="introduce-title">学员点评</div>
                </div>
                <div className="introduce-content" style={{ marginTop: '10px' }}>
                    {
                        dpList?.list?.map((item, index) => (
                            <div key={index}>
                                <IntroduceItem data={item} />
                            </div>
                        ))
                    }
                </div>
                <div className="train-car-detail-shell">
                    {!dpList?.pagination?.totalRows &&
                        <EmptyList />
                    }
                    <div className="train-car-page">
                        <MyPagination
                            total={dpList?.pagination?.totalRows}
                            pageSize={dpList?.pagination?.pageSize}
                            pageIndex={dpList?.pagination?.pageIndex}
                            changePage={changePage}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StarCoachDetail