// import { Navigate } from "react-router-dom";
import Home from '@/pages/home' // 首页
import TrainCar from '@/pages/trainCar' // 练车场地
import TrainCarDetail from '@/pages/trainCarDetail' // 练车场地详情
import StarCoach from '@/pages/starCoach' // 明星教练
import StarCoachDetail from '@/pages/starCoachDetail' // 明星教练详情
import ClassBrief from '@/pages/classBrief' // 班制介绍
import ClassBriefDetail from '@/pages/classBriefDetail' // 班制介绍详情
import SignupNotice from '@/pages/signupNotice' // 报名须知
import TenantInfo from '@/pages/tenantInfo' // 驾校资讯
import TenantInfoDetail from '@/pages/tenantInfoDetail' // 驾校资讯详情
import ContactUs from '@/pages/contactUs' //联系我们
import Myorder from '@/pages/myorder' // 我的订单

const routes = [
    {
        path: "/home/:uid",
        element: <Home />,
    },
    {
        path: "/trainCar/:uid",
        element: <TrainCar />,
    },
    {
        path: "/trainCarDetail/:uid",
        element: <TrainCarDetail />,
    },
    {
        path: "/starCoach/:uid",
        element: <StarCoach/>,
    },
    {
        path: "/starCoachDetail/:uid",
        element: <StarCoachDetail/>,
    },
    {
        path: "/classBrief/:uid",
        element: <ClassBrief/>,
    },
    {
        path: "/classBriefDetail/:uid",
        element: <ClassBriefDetail/>,
    },
    {
        path: "/signupNotice/:uid",
        element: <SignupNotice/>,
    },
    {
        path: "/tenantInfo/:uid",
        element: <TenantInfo/>,
    },
    {
        path: "/tenantInfoDetail/:uid",
        element: <TenantInfoDetail/>,
    },
    {
        path: "/contactUs/:uid",
        element: <ContactUs/>,
    },
    {
        path: "/myorder/:uid",
        element: <Myorder/>,
    }
    // {
    //     path: "/*",
    //     element: <Navigate to="/home" />,
    // },
]

export default routes