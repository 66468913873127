import React from "react";
import { realname, coach_dingwei, coach_train } from '@/utils/base64'
import { user_img } from '@/imgs'
import './style.less'

interface Props {
    data: any
}

const CoachItem: React.FC<Props> = (props) => {
    const { data } = props
    return (
        <div className="coach-item">
            <div className="coach-item-head-img">
                <img src={data?.coachPhotoUrl ? data?.coachPhotoUrl + '?w=84&h=84' : user_img} />
            </div>
            <div className="coach-item-info">
                <div className="coach-info-top">
                    <div className="coach-info-name">{data?.coachName}</div>
                    <div className="coach-info-realname"><img src={realname} /></div>
                    <div className="coach-info-duration">{data?.coachYear || 1}年教龄</div>
                </div>
                {
                    !!data?.coachAreaList?.length &&
                    <div className="coach-info-cartype">
                        <img src={coach_train} />
                        <div className="coach-info-cartype-text">{data?.coachAreaList?.join('、')}</div>
                    </div>
                }
                <div className="coach-info-evaluate">
                    <img src={coach_dingwei} />
                    <div className="coach-info-evaluate-text">
                        服务<span className="coach-info-text-number" style={{ marginRight: '10px' }}>{data?.score}</span>
                        点评<span className="coach-info-text-number">{data?.dpCount}</span>条
                    </div>
                </div>
                <div className="coach-info-tab">
                    {
                        data?.dpInfo?.slice(0, 3)?.map((item: string, index: number) => {
                            return (
                                <div className="coach-tab-item" style={{ marginLeft: index !== 0 ? '6px' : '' }} key={index}>{item}</div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default CoachItem
