import React, { useMemo, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { banner_img_3 } from '@/imgs'
import { getOutList, syncTrackLog } from '@/api/home'
import ClassBriefItem from '@/components/classBriefItem'
import MyPagination from '@/components/pagination'
import EmptyList from '@/components/emptyList'
import './style.less'

const ClassBrief: React.FC = () => {
    const navigate = useNavigate()
    // 当前路由路径
    const { pathname } = useLocation()
    // 获取驾校ID
    const id = useMemo(() => {
        const arr = pathname?.split('/').filter((item) => (item))
        return arr[arr.length - 1]?.split("?")[0]?.replace(/[^0-9]/g, '')
    }, [pathname])

    // 训练场数据
    const [data, setData] = useState({
        list: [],
        pagination: {
            pageIndex: 1,
            pageSize: 6,
            totalPages: 1,
            totalRows: 1,
        }
    })

    // 获取列表数据
    useEffect(() => {
        getData({ pageIndex: 1 })
    }, [id])

    const getData = async (page: any) => {
        try {
            const res: any = await getOutList({ ...page, pageSize: 6 })
            res.data.list.forEach((item: any) => {
                if (item.remarks) {
                    item.remarksList = item.remarks
                        .replace(/[\.。；]/g, ';')
                        .split(';')
                        .filter((item: any) => item)
                }
            })
            setData(res?.data)
        } catch (e) {

        }
    }

    // 改变分页
    const changePage = (pageIndex: number) => {
        getData({ pageIndex: pageIndex })
    }

    // 跳转详情
    const goDetail = (value: any) => {
        syncTrackLog({
            appId: "4",
            trackId: "jxtjp_gw_click",
            tenantId: id,
            clientInfo: navigator.userAgent,
            attrMap: {
                attr2: `/classBriefDetail/${id}?id=${value?.id}`,
                attr1: '班型介绍-详情'
            }
        })
        window.scrollTo(0, 0)
        navigate(`/classBriefDetail/${id}?id=${value?.id}`)
    }


    return <div className="class-brief-shell">
        <div className="train-car-bg"><img src={banner_img_3} alt="" /></div>
        <div className="train-car-center">
            <div className="train-car-title">班型介绍</div>
            <div className="train-car-warrper">
                {
                    data?.list?.map((item, index) => (
                        <div
                            key={index}
                            onClick={(e) => {
                                e.stopPropagation()
                                goDetail(item)
                            }}
                        >
                            <ClassBriefItem data={item} />
                        </div>
                    ))
                }
                {!data?.pagination?.totalRows &&
                    <EmptyList />
                }
            </div>
            <div className="train-car-page">
                <MyPagination
                    total={data?.pagination?.totalRows}
                    pageSize={data?.pagination?.pageSize}
                    pageIndex={data?.pagination?.pageIndex}
                    changePage={changePage}
                />
            </div>
        </div>
    </div>
}

export default ClassBrief