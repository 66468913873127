import React, { useState } from "react";
import TitleShell from '@/components/titleShell'
import { wisdom_banner_1, wisdom_banner_2, wisdom_banner_3 } from '@/imgs'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { EffectFade, Autoplay, Pagination, EffectCreative } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import './style.less'

interface Props {
    activeIndex: number
    setActiveIndex: Function
}
const MySwiperPagination: React.FC<Props> = ({ activeIndex, setActiveIndex }) => {
    const swiper = useSwiper();
    // 点击切换
    const goSlide = (index: number) => {
        swiper.slideTo(index, 300)
        setActiveIndex(index)
    }
    return (
        <div className="wisdom-swiper-pagination swiper-pagination">
            <div
                className={activeIndex === 0 ? 'pagination-item pagination-item-active' : 'pagination-item'}
                onClick={() => (goSlide(0))}
            >
                <div className="pagination-item-title">智能班主任</div>
                <div className="pagination-item-text">全程守护学车旅程</div>
            </div>
            <div
                className={activeIndex === 1 ? 'pagination-item pagination-item-active' : 'pagination-item'}
                onClick={() => (goSlide(1))}
            >
                <div className="pagination-item-title">智能模拟器</div>
                <div className="pagination-item-text">最懂培训的模拟器</div>
            </div>
            <div
                className={activeIndex === 2 ? 'pagination-item pagination-item-active' : 'pagination-item'}
                onClick={() => (goSlide(2))}
            >
                <div className="pagination-item-title">智能机器人</div>
                <div className="pagination-item-text">智能/高效/有趣</div>
            </div>
        </div>
    )
}
const Wisdom: React.FC = () => {

    // 目前选中展示的
    const [activeIndex, setActiveIndex] = useState(0)

    return (
        <div className="wisdom-shell">
            <div className="wisdom-shell-title">
                <TitleShell title="智慧教学" text="全智慧打造学车体验" url='' />
            </div>
            <div className="wisdom-intro-content">
                <div className="wisdom-intro-content-bg">
                    <div className="wisdom-intro-substance">
                        <Swiper

                            // spaceBetween={30}
                            // effect={'fade'}
                            effect={'creative'}
                            // loop={true}
                            autoplay={{
                                delay: 2500,
                                pauseOnMouseEnter: true,
                            }}
                            creativeEffect={{
                                prev: {
                                    // shadow: true,
                                    opacity: 0
                                },
                                next: {
                                    translate: [0, '100%', 0],
                                },
                            }}
                            onSlideChange={(e: any) => {
                                console.log(e?.activeIndex, '====>')
                                setActiveIndex(e?.activeIndex)
                            }}
                            modules={[EffectFade, Pagination, Autoplay, EffectCreative]}
                            className="swiper wisdom-swiper"
                        >
                            <MySwiperPagination activeIndex={activeIndex} setActiveIndex={setActiveIndex} />
                            <div style={{overflow: 'hidden'}}>
                                <SwiperSlide className="swiper-slide">
                                    <img src={wisdom_banner_1} className="swiper-slide-img" />
                                </SwiperSlide>
                                <SwiperSlide className="swiper-slide">
                                    <img src={wisdom_banner_2} className="swiper-slide-img" />
                                </SwiperSlide>
                                <SwiperSlide className="swiper-slide">
                                    <img src={wisdom_banner_3} className="swiper-slide-img" />
                                </SwiperSlide>
                            </div>

                        </Swiper>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Wisdom
