import React, { useState, useEffect, useRef, useMemo } from 'react'
import { message } from 'antd'
import { useNavigate, useLocation } from "react-router-dom";
import { inviteMobileMsgCode, schWebLogin, syncTrackLog } from '@/api/home'
import { header_logo } from '@/imgs'
import { isPhone, setToken } from '@/utils'

import './style.less'

declare const window: any
let captchaIns: any = ''
let timer: any = null

interface Props {
    setShowLogin: Function // 隐藏登陆页面
}

const Login: React.FC<Props> = (props) => {
    const {setShowLogin} = props
    const navigate = useNavigate()
    // 当前路由路径
    const { pathname } = useLocation()
    // 获取驾校ID
    const id = useMemo(() => {
        const arr = pathname?.split('/').filter((item) => (item))
        const ydtCode = arr[arr.length - 1]?.split("?")[0]?.replace(/[^0-9]/g, '')
        return ydtCode ? ydtCode : '1707325546549014529'
    }, [pathname])
    const cellPhoneNew = useRef('')
    const codeNew = useRef('')
    const timeRef = useRef(60)
    // 手机号
    const [cellPhone, setCellPhone] = useState('')
    // 验证码
    const [code, setCode] = useState('')
    // 手机号错误提示
    const [cellPhoneError, setCellPhoneError] = useState(false)
    // 验证码错误提示
    const [codeError, setCodeError] = useState(false)
    const [othersInfo, setOthersInfo] = useState({
        code: '', // 短信验证码
        time: 60, //发送验证码的等待时间
        responseId: '', // 验证码回传id
    })

    useEffect(() => {
        loadJsFn()
    }, [])

    // 加载u盾
    const loadJsFn = () => {
        const script = document.createElement('script')
        script.onload = () => {
            window.initNECaptcha(
                {
                    // config对象，参数配置
                    captchaId: 'e11978dc94fe431eb2ec9c510ddf97b9',
                    element: '#adminbind',
                    mode: 'popup',
                    width: '380px',
                    // 用户验证码验证成功后，进行实际的提交行为
                    onVerify: (err: any, data: any) => {
                        if (data) {
                            handleSendCode(data.validate)
                        }
                    },
                },
                function onload(instance: any) {
                    // 初始化成功后得到验证实例instance，可以调用实例的方法
                    captchaIns = instance
                    console.log(instance, '第三方验证码登录你是个啥玩意')
                },
                function onerror(err: any) {
                    console.log('error', err)
                    // 初始化失败后触发该函数，err对象描述当前错误信息
                }
            )
        }
        script.async = true
        script.src = 'https://cstaticdun.126.net/load.min.js'
        document.head.appendChild(script)
    }

    //重置验证码
    const resetCode = () => {
        setOthersInfo({
            time: 60,
            responseId: '',
            code: ''
        })
        timeRef.current = 60
        if (!!timer) {
            clearInterval(timer)
            timer = null
        }
    }

    // 获取验证码
    const handleSendCode = (successToken: any) => {
        resetCode()
        inviteMobileMsgCode({ phone: cellPhoneNew.current, loginType: 1, successToken: successToken })
            .then((res: any) => {
                console.log(res, '=======>')
                if (res?.code !== 0) {
                    message.error(res?.msg || res?.message)

                    return
                }
                if (res?.data?.rid) {
                    setOthersInfo({
                        time: 60,
                        responseId: res?.data.rid,
                        code: ''
                    })
                    timer = setInterval(() => {
                        timeRef.current = --timeRef.current
                        setOthersInfo((val) => {
                            return { ...val, time: timeRef.current }
                        })
                        console.log(timeRef.current, '======>time')
                        if (timeRef.current <= 0) {
                            setOthersInfo({
                                time: 60,
                                responseId: '',
                                code: ''
                            })
                            timeRef.current = 60
                            clearInterval(timer)
                        }
                    }, 1000)
                }
            })
            .catch((err: any) => {
                console.log(err, '验证码错误信息')
                // message.error(err)
            })
    }

    // 获取验证码
    const getCode = () => {
        // 已经获取过验证码，正在读秒
        if (othersInfo.responseId) {
            return
        }
        if (!isPhone(cellPhoneNew.current)) {
            setCellPhoneError(true)
            return
        } else {
            setCellPhoneError(false)
        }
        captchaIns && captchaIns.refresh()
        captchaIns && captchaIns.popUp()
    }

    // 登陆
    const loginFn = async () => {
        try {
            if (!isPhone(cellPhoneNew.current)) {
                setCellPhoneError(true)
                return
            } else {
                setCellPhoneError(false)
            }
            if (!codeNew.current) {
                setCodeError(true)
                return
            } else {
                setCodeError(false)
            }
            const res: any = await schWebLogin({
                phone: cellPhoneNew.current,
                loginType: 1,
                code: codeNew.current,
                rid: othersInfo.responseId
            })
            if (res?.code === 200 || res?.code === 0) {
                setToken('openCookieid', res?.data?.token, {})
                syncTrackLog({
                    appId: "4",
                    trackId: "jxtjp_gw_click",
                    clientInfo: navigator.userAgent,
                    tenantId: id,
                    attrMap: {
                        attr2: cellPhoneNew.current,
                        attr1: '登陆'
                    }
                })
                navigate(`/myorder/${id}`)
                setShowLogin(false)
            } else {
                message.error(res?.message)
            }   
        } catch (e) {

        }
    }

    return (
        <div className='login-wraaper-bg'>
            <div className='login-wraaper'>
                <div className='login-logo'><img src={header_logo} /></div>
                <div style={{ marginTop: '40px', position: 'relative' }}>
                    <i className="jxt jxt-Vector cellPhone-icon"></i>
                    {
                        !!cellPhone &&
                        <i
                            className="jxt jxt-a-Clippathgroup2 clear-icon"
                            onClick={() => {
                                cellPhoneNew.current = ''
                                setCellPhone('')
                            }}
                        ></i>
                    }
                    <input
                        type="number"
                        className='phone-input'
                        maxLength={11}
                        placeholder='请填写手机号码'
                        value={cellPhone}
                        onChange={e => {
                            cellPhoneNew.current = (e.target.value || '').slice(0, 11)
                            setCellPhone((e.target.value || '').slice(0, 11))
                        }}
                    />
                    <div className='input-error-text'>{cellPhoneError && '请输入有效的手机号'}</div>
                </div>
                <div style={{ position: 'relative' }}>
                    <i className="jxt jxt-a-Vector1 cellPhone-icon"></i>
                    {/* <span v-if="!othersInfo.responseId" style="color: #f87315" @click="getCode"></span>
                        <span v-else style="color: rgba(248, 115, 21, 0.5)"></span> */}
                    <span
                        className='code-btn'
                        onClick={getCode}
                    >
                        {!othersInfo.responseId ? '获取验证码' : `重新获取(${othersInfo.time})`}
                    </span>
                    <input
                        type="number"
                        className='phone-input'
                        maxLength={6}
                        placeholder='请填写验证码'
                        value={code} onChange={e => {
                            codeNew.current = (e.target.value || '').slice(0, 6)
                            setCode((e.target.value || '').slice(0, 6))
                        }}
                    />
                    <div className='input-error-text'>{codeError && '请输入有效的验证码'}</div>
                </div>
                <div className='login-btn' onClick={loginFn}>登录/注册</div>
                <div className='login-text'>未注册手机号验证后自动创建账号</div>
                <div className='login-protect-text'>注册即同意<a href='https://j1.58cdn.com.cn/dist/jxt/agreement/jxt-empower.html' target="_blank">《用户使用协议》</a></div>
                <i className="jxt jxt-guanbi guanbi-icon" onClick={() => setShowLogin(false)}></i>
            </div>
            <div id='adminbind'></div>
        </div>
    )
}

export default Login
