import React, { useEffect, useMemo, useState } from "react";
import './style.less'
import { Breadcrumb } from 'antd'
import { useLocation, useNavigate } from "react-router-dom";
import { getNewDetail } from '@/api/jxNews'
import { syncTrackLog } from "@/api/home";



const TenantInfoDetail: React.FC = () => {
    const { pathname, search } = useLocation()

    // 获取驾校ID
    const id = useMemo(() => {
        const arr = pathname?.split('/').filter((item) => (item))
        return arr[arr.length - 1]?.split("?")[0]?.replace(/[^0-9]/g, '')
    }, [pathname])

    const query = useMemo(() => {
        return {
            newId: search?.split('?')[1]?.split('&')[0]?.split('=')[1] || '',
            type: search?.split('?')[1]?.split('&')[1]?.split('=')[1] || ''
        }
    }, [search])


    const navigate = useNavigate()
    const [info, setInfo] = useState<any>({
        text: '',
        title: '',
        dateShow: '',
        afterText: {
            id: '',
            title: ''
        }
    })


    useEffect(() => {
        getList()
    }, [query, id])


    const seeNext = (nextId: string) => {
        syncTrackLog({
            appId: 4,
            trackId: 'jxtjp_gw_click',
            clientInfo: navigator.userAgent,
            tenantId: id,
            attrMap: {
                attr1: '驾校咨询详情-下一篇按钮',
                attr2: pathname
            }
        })
        navigate(`/tenantInfoDetail/${id}?id=${nextId}&type=${query.type}`)
    }


    const getList = async () => {

        let res: any = await getNewDetail({ id: query.newId, type: query.type })
        console.log(JSON.stringify(res.data.text))
        if (res.code === 0) {
            setInfo(res.data)
        }
    }



    return (
        <div className="jx-news-detail">
            <div className="jx-new-crumbs">
                <Breadcrumb
                    items={[
                        {
                            title: <div
                                className="breadcrumb-btn"
                                onClick={() => {
                                    window.scrollTo(0, 0)
                                    navigate(`/home/${id}`)
                                }}
                            >首页</div>,
                        },
                        {
                            type: 'separator',
                            separator: <span>//</span>,
                        },
                        {
                            title: <div
                                className="breadcrumb-btn"
                                onClick={() => {
                                    window.scrollTo(0, 0)
                                    navigate(`/tenantInfo/${id}`)
                                }}>驾校资讯</div>,
                        },
                        {
                            type: 'separator',
                            separator: <span>//</span>,
                        },
                        {
                            title: info.title,
                        },
                    ]}
                    separator=''
                />
                {/* 首页 <span style={{ color: '#101D37' }}>//</span> 驾校资讯 // {info.title} */}
            </div>
            <div className="whiteBg">
                <div className="new-detail">
                    <div className="new-header">
                        <div className="new-title">{info.title}</div>
                        <div className="new-date">{info.dateShow}</div>
                    </div>
                    <div className="new-text">
                        <div dangerouslySetInnerHTML={{ __html: info?.text }}></div>
                    </div>
                </div>
            </div>
            <div className="new-next">
                {info.afterText?.title &&
                    <>
                        <span>下一篇：</span>
                        <span onClick={() => seeNext(info.afterText?.id)}> {info.afterText?.title}</span>
                    </>
                }
            </div>

        </div>
    )
}

export default TenantInfoDetail