import cookie from 'js-cookie'
declare const window: any

const TokenKey = 'openCookieid'

export const setToken = (cookieName = TokenKey, data: any, options = {}) => cookie.set(cookieName, data, options)

export const getToken = (cookieName = TokenKey) => cookie.get(cookieName) || ''

export const removeToken = (cookieName = TokenKey, options = {}) => cookie.remove(cookieName, options)

// 初始化智齿
export const initZhiChi = () => {
    try {
        if (typeof window.zc === 'undefined') {
            (function (w, d, e) {
                w[e] = function () {
                    w.cbk = w.cbk || []
                    w.cbk.push(arguments);
                }
                const x = d.createElement("script");
                x.async = true;
                x.id = "zhichiScript";
                // ***为您的自定义域名
                x.src = "https://jxt.soboten.com/chat/frame/v6/entrance.js?sysnum=4d43f6416ac84028adb1d29bede67376";

                x.className = "zhiCustomBtn";
                d.body.appendChild(x);
            })(window, document, "zc");
            window.zc("config", {
                custom: true, //设置自定义生效 第二步
            })
        }
    } catch (e) {
        console.log(e, '=======>')
    }
}

// 打开智齿消息框
export const openZhiChi = () => {
    window.zc('frame_status', (data: any) => {
        console.log(data)
    })
}

// 格式化手机号
export const getApplicationMobile = (text: string) => {
    if (text?.length === 10) {
        return text?.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
    }
    return text?.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')
}

// 格式化手机号
export const getPhone = (text: string) => {
    if (text?.length === 10) {
        return text?.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3')
    }
    return text?.replace(/(\d{3})(\d{4})(\d{4})/, '$1 $2 $3')
}

export const addScript = (url: string, onLoadFn: Function, onErrorFn: Function) => {
    if (document.getElementById('baidu_map')) {
        return
    }
    const newScript = document.createElement('script')
    newScript.id = 'baidu_map'
    newScript.setAttribute('type', 'text/javascript')
    newScript.setAttribute('src', url)
    document.body.appendChild(newScript)
    // window.onBMapCallback = function() {
    //     //@ts-ignore
    //    onLoadFn()
    // }
    newScript.onload = () => {
        onLoadFn()
    }
    newScript.onerror = () => {
        onErrorFn()
    }
}


export const LoadBaiduMapScript = () => {
    const BMap_URL = '//api.map.baidu.com/api?type=webgl&v=1.0&ak=fvXiyEhRupb0dUDsLPbDnc7Ixc7XpVif&callback=onBMapCallback'
    // @ts-ignore
    return new Promise((resolve) => {
        // 如果已加载直接返回
        //@ts-ignore
        // if (typeof BMapGL !== 'undefined') {
        //     //@ts-ignore
        //     resolve(BMapGL)
        //     return true
        // }
        // 百度地图异步加载回调处理
        // @ts-ignore
        window.onBMapCallback = function () {
            console.log('========>地图加载完毕')
            //@ts-ignore
            resolve(BMapGL)
        }
        // 插入script脚本
        let scriptNode1 = document.createElement('script')
        scriptNode1.setAttribute('type', 'text/javascript')
        scriptNode1.setAttribute('src', BMap_URL)
        document.body.appendChild(scriptNode1)
    })
}

// 正则匹配dom标签进行替换
export const getText = (text: string) => {
    try {
        return text.replace(/<[^>]+>/g, '')
    } catch (e) {

    }
}

 //手机号
 export function isPhone (val:string) {
    return /^0?(13[0-9]|14[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9])[0-9]{8}$/.test(val)
}


