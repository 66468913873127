import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TitleShell from '@/components/titleShell'
import TrainingGround from '@/components/trainingGround'
import EmptyList from '@/components/emptyList'
import { getList } from '@/api/home'
import { syncTrackLog } from '@/api/home'
import './style.less'

interface Props {
    schoolId: string // 驾校ID
}

const TraincCar: React.FC<Props> = (props) => {
    const { schoolId } = props
    const navigate = useNavigate()
    // 当前路由路径
    const { pathname } = useLocation()
    // 获取驾校ID
    const id = useMemo(() => {
        const arr = pathname?.split('/').filter((item) => (item))
        return arr[arr.length - 1]?.split("?")[0]?.replace(/[^0-9]/g, '')
    }, [pathname])
    const [data, setData] = useState({
        list: [],
        pagination: {
            pageIndex: 1,
            pageSize: 4,
            totalPages: 1,
            totalRows: 2,
        }
    })

    useEffect(() => {
        getData()
    }, [id])

    // 获取数据
    const getData = async () => {
        try {
            const res: any = await getList({ isHome: true, pageIndex: 1, pageSize: 4 })
            setData(res?.data)
        } catch (e) {

        }
    }

    // 跳转详情
    const goDetail = (value: any) => {
        syncTrackLog({
            appId: "4",
            trackId: "jxtjp_gw_click",
            clientInfo: navigator.userAgent,
            tenantId: id,
            attrMap: {
                attr2: `/trainCarDetail/${id}?id=${value?.id}`,
                attr1: '首页练车场地-详情'
            }
        })
        window.open(`/trainCarDetail/${id}?id=${value?.id}`)
        // window.scrollTo(0, 0)
        // navigate(`/trainCarDetail/${id}?id=${value?.id}`)
    }

    return (
        <div className="trainc-car-shell">
            <TitleShell title="练车场地" text="就近练车" url={`/trainCar/${schoolId}`} />
            <div className="trainc-car-content">
                {
                    data?.list?.map((item, index) => (
                        <div
                            key={index}
                            style={{ marginRight: ((index + 1) % 4) === 0 ? '' : '20px' }}
                            onClick={() => goDetail(item)}
                        >
                            <TrainingGround data={item} />
                        </div>
                    ))
                }
                {
                    !data?.list?.length &&
                    <EmptyList/>
                }
            </div>
        </div>
    )
}

export default TraincCar
