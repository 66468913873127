import React, { useState, useEffect, useMemo, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Autoplay } from 'swiper/modules';
import { Breadcrumb } from 'antd'
import { class_bg_1 } from '@/imgs'
import { getClassDetail, syncTrackLog } from '@/api/home'
import { openZhiChi } from '@/utils'
import EmptyList from '@/components/emptyList'
import './style.less'
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

declare const window: any

let swiper2: any

const ClassBriefDetail: React.FC = () => {
    const navigate = useNavigate()
    // 当前路由路径
    const { pathname, search } = useLocation()
    // 轮播当前图片
    const [activeIndex, setActiveIndex] = useState(1)
    // 获取驾校ID
    const id = useMemo(() => {
        const arr = pathname?.split('/').filter((item) => (item))
        return arr[arr.length - 1]?.split("?")[0]?.replace(/[^0-9]/g, '')
    }, [pathname])
    // 训练场ID
    const detailId = useMemo(() => {
        const arr = search?.split('id=')
        return arr[arr.length - 1]
    }, [search])
    // 训练场数据
    const [data, setData] = useState<any>(null)
    // 当前页
    // const [, setActiveIndex] = useState(1)
    // const activeIndex = useRef<any>(1)

    // 获取列表数据
    useEffect(() => {
        if (detailId) {
            getData()
        }
    }, [detailId])

    // useEffect(() => {
    //     setTimeout(() => {
    //         initSwiper()
    //     }, 300)
    // }, [data])

    // const formatRichText = (html: any) => {
    //     let newContent= html.replace(/<img[^>]*>/gi,function(match: any){
    //         //重点改了这里，直接删掉了存在的style，无论是在前还是后的style
    //         match = match.replace(/style="[^"]+"/gi, '').replace(/style\s*?=\s*?([‘"])[\s\S]*?\1/ig, '');
    //         match = match.replace(/width="[^"]+"/gi, '').replace(/width='[^']+'/gi, '');
    //         match = match.replace(/height="[^"]+"/gi, '').replace(/height='[^']+'/gi, '');
    //         return match;
    //     });
    //     newContent = newContent.replace(/style="[^"]+"/gi,function(match: any){
    //         match = match.replace(/width:[^;]+;/gi, 'width:100%;').replace(/width:[^;]+;/gi, 'width:100%;');
    //         return match;
    //     });
    //     newContent = newContent.replace(/<br[^>]*\/>/gi, '');
    //     newContent = newContent.replace(/\<img/gi, '<img style="width:100%;height:auto;display:block;margin:0px 0;"');
    //     return newContent;
    // }

    const getData = async () => {
        try {
            const res: any = await getClassDetail({ id: detailId })
            if (res?.data?.remarks) {
                res.data.remarksList = res?.data?.remarks
                    .replace(/[\.。；]/g, ';')
                    .split(';')
                    .filter((item: any) => item)
            }
            // res.data.classContent = formatRichText(res?.data?.classContent)
            setData(res?.data)
        } catch (e) {

        }
    }

    // // 轮播图
    // const goSilid = (index: number) => {
    //     swiper2.slideTo(index, 100)
    //     activeIndex.current = index + 1
    //     setActiveIndex(index + 1)
    //     const dom: any = document.querySelector('.train-my-Swiper .swiper-wrapper')
    //     if (dom) {
    //         if (index >= 2 && data?.picUrlList?.length > 3) {
    //             dom.style.marginTop = (-160 * (index - 1)) + 'px'
    //         } else {
    //             dom.style.marginTop = '0px'
    //         }
    //     }
    // }
    // const initSwiper = () => {
    //     try {
    //         activeIndex.current = 1
    //         const dom: any = document.querySelector('.train-my-Swiper .swiper-wrapper')

    //         dom.style.marginTop = '0px'
    //         swiper2 = new Swiper(".train-mySwiper2", {
    //             spaceBetween: 10,
    //             noSwiping: true,
    //         });
    //         swiper2.updateSize()
    //         swiper2.updateSlides()
    //         swiper2.slideTo(0, 100)
    //     } catch (e) {

    //     }
    // }

    // 立即质询
    const goConsult = () => {
        syncTrackLog({
            appId: "4",
            trackId: "jxtjp_gw_click",
            tenantId: id,
            clientInfo: navigator.userAgent,
            attrMap: {
                attr2: pathname,
                attr1: '练车场地详情-立即质询'
            }
        })
        openZhiChi()
    }


    return (
        <div className="class-brief-detail">
            <div className="train-car-detail-top">
                <div className="train-detail-top-content">
                    <Breadcrumb
                        items={[
                            {
                                title: <div
                                    className="breadcrumb-btn"
                                    onClick={() => {
                                        window.scrollTo(0, 0)
                                        navigate(`/home/${id}`)
                                    }}>首页</div>,
                            },
                            {
                                type: 'separator',
                                separator: <span>//</span>,
                            },
                            {
                                title: <div
                                    className="breadcrumb-btn"
                                    onClick={() => {
                                        window.scrollTo(0, 0)
                                        navigate(`/classBrief/${id}`)
                                    }}>班型介绍</div>,
                            },
                            {
                                type: 'separator',
                                separator: <span>//</span>,
                            },
                            {
                                title: data?.name,
                            },
                        ]}
                        separator=''
                    />
                </div>
            </div>
            <div className="train-car-detail-content">
                <div className="train-car-detail-shell">
                    <div className="train-car-detail-info">
                        <div className="train-detail-info-img">
                            <Swiper
                                spaceBetween={30}
                                // effect={'fade'}
                                autoplay={{ delay: 3000, pauseOnMouseEnter: true }}
                                // loop={true}
                                // pagination={{
                                //     clickable: true,
                                // }}
                                onActiveIndexChange={(data: any) => {
                                    console.log(data, '=====>')
                                    setActiveIndex(data?.activeIndex + 1)
                                }}
                                modules={[EffectFade, Autoplay]}
                                className="train-detail-info-img-swiper"
                            >
                                {
                                    !!data?.picUrlList?.length && data?.picUrlList?.map((item: string, index: number) => (
                                        <SwiperSlide key={index} className="swiper-slide-item swiper-slide-item-1">
                                            <img src={item} alt="" />
                                        </SwiperSlide>
                                    ))
                                }
                                {
                                    !data?.picUrlList?.length &&
                                    <SwiperSlide className="swiper-slide-item swiper-slide-item-1">
                                        <img src={class_bg_1} alt="" />
                                    </SwiperSlide>
                                }
                            </Swiper>
                            {
                                data?.picUrlList?.length > 1 &&
                                <div className="class-swiper-pages">{activeIndex}/{data?.picUrlList?.length}</div>
                            }
                            {/* <img src={data?.picUrlList?.length ? data?.picUrlList[0] + '?w=195&h=146' : class_bg_1} alt="" /> */}
                        </div>
                        <div className="train-detail-info-right">
                            <div className="info-right-top">
                                <div className="info-top-content">
                                    <div className="info-top-title">
                                        {/* <span className="info-top-title"></span> */}
                                        {data?.name}
                                        <div className="course-name">{data?.dicTrainType}</div>
                                    </div>
                                    <div className="info-top-vehicle">
                                        {
                                            !!data?.naBen &&
                                            <div className="class-tab-item">最快{data?.naBen}天拿证</div>
                                        }
                                        {
                                            !!data?.ctdK2 &&
                                            <div className="class-tab-item" style={{ marginLeft: '6px' }}>{data?.ctdK2}</div>
                                        }
                                        {
                                            !!data?.learnDriverTime &&
                                            <div className="class-tab-item" style={{ marginLeft: '6px' }}>{data?.learnDriverTime}</div>
                                        }
                                    </div>
                                </div>
                                <div className="info-right-top-btn" onClick={goConsult}>报名咨询</div>
                            </div>
                            <div className="info-contacts">
                                <i className="jxt jxt-cheche carType-icon"></i>
                                <div className="info-contacts-content">
                                    车辆：{data?.carType}
                                </div>
                            </div>
                            <div className="class-info-money">
                                <span style={{ fontSize: '14px', fontWeight: 'initial' }}>活动价</span>
                                ¥{data?.price}
                                <span style={{ fontSize: '14px', color: '#999999', textDecoration: 'line-through', fontWeight: 'initial', marginLeft: '10px' }}>原价¥{data?.originalPrice || '0'}</span>
                            </div>
                        </div>
                    </div>
                    {
                        !!data?.remarksList?.length &&
                        <div className="train-car-synopsis">
                            <div className="synopsis-title">班型描述</div>
                            {data?.remarksList?.length ?
                                <div className="synopsis-content">
                                    {data?.remarksList?.map((item: string, index: number) => {
                                        return <div style={{ fontSize: '16px', lineHeight: '24px', color: '#101D37' }} key={index}>{item}</div>
                                    })}
                                </div> :
                                <EmptyList />
                            }
                        </div>
                    }
                    {
                        !!data?.classContent &&
                        <div className="train-car-synopsis" style={{ marginTop: !!data?.remarksList?.length ? '32px' : '60px' }}>
                            <div className="synopsis-title">班型详情</div>
                            <div className="new-text">
                                <div dangerouslySetInnerHTML={{ __html: data?.classContent }}></div>
                            </div>
                        </div>
                    }

                    {/* {
                        !!data?.picUrlList?.length ?
                            <div className="train-car-imgs">
                                <div className="swiper train-mySwiper2">
                                    <div className="swiper-wrapper">
                                        {
                                            data?.picUrlList?.map((item: string, index: number) => {
                                                return (
                                                    <div
                                                        className="swiper-slide my-Swiper-slide swiper-no-swiping"
                                                        key={index}
                                                    >
                                                        <img src={item + '?w=1369&h=769'} />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div className="train-my-swiper">
                                    <div className="swiper train-my-Swiper">
                                        <div className="swiper-wrapper" style={{ minHeight: '520px' }}>
                                            {
                                                data?.picUrlList?.map((item: string, index: number) => {
                                                    return (
                                                        <div
                                                            onClick={() => goSilid(index)}
                                                            className={activeIndex.current - 1 === index ? 'swiper-slide my-Swiper-slide my-swiper-slide-active' : 'swiper-slide my-Swiper-slide'}
                                                            key={index}
                                                        >
                                                            <img src={item + '?w=300&h=166'} />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="train-car-imgs-page">
                                    {activeIndex.current}/{data?.picUrlList?.length}
                                </div>
                            </div> :
                            <>
                                <EmptyList />
                            </>
                    } */}
                </div>
            </div>
        </div>
    )
}

export default ClassBriefDetail