import axios from 'axios';
import BASE_URL from './base'
import { getToken } from '@/utils'

const request = axios.create({
    timeout: 1500000,  // 请求超时时间
});

// 请求拦截器
request.interceptors.request.use(
    config => {
        // 在发送请求之前做些什么：例如添加token
        const id = localStorage.getItem('id')
        config.headers['ydtCode'] = id
        // 订单列表接口
        if (config?.url?.includes('/api/schWeb/personal/order/list')) {
            const openCookieid = getToken('openCookieid')
            config.headers['openCookieid'] = openCookieid
        }
        return config;
    },
    error => {
        // 请求错误
        return Promise.reject(error);
    }
);

// 响应拦截器
request.interceptors.response.use(
    response => {
        // 对响应数据做点什么
        const res = response.data;
        // 根据你的业务处理回调
        if (res.code !== 0 && res.code !== 200) {
            if (response?.config?.url?.includes("/api/schWeb/firstPage/search")) {
                return res
            }
            if (response?.config?.url?.includes("/api/schWeb/code")) {
                return res
            }
            if (response?.config?.url?.includes("/api/schWeb/personal/order/list")) {
                return res
            }
            if (response.config.url?.includes('/api/schWeb/clue/add')) {
                // message.error(response.data.message)
                return res
            } else {
                // 处理错误
                return Promise.reject(new Error(res.message || res.msg || 'Error'));
            }

        } else {
            return res;
        }
    },
    error => {
        // 对响应错误做点什么
        console.log('err' + error);
        return Promise.reject(error);
    }
);

export default {
    get<T>(url: string, params?: {}, base = 'BA_API'): Promise<T> {
        let headers = {
            Pragma: 'no-cache',
            'Cache-Control': 'must-revalidate,no-cache,no-store',
            Expires: 0,
        }
        return new Promise((resolve, reject) => {
            request
                .get(BASE_URL[base] + url, {
                    params,
                    headers,
                })
                .then((res: any) => {
                    res !== false ? resolve(res) : reject(res)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    }, //
    postJson<T>(url: string, data?: {}, base = 'BA_API'): Promise<T> {
        return new Promise((resolve, reject) => {
            request
                .post(BASE_URL[base] + url, data)
                .then((res: any) => {
                    res !== false ? resolve(res) : reject(res)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },

}