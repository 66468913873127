import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { syncTrackLog } from '@/api/home'
import { rout_1 } from '@/imgs'
import './style.less'

interface Props {
    title: string // 标题
    text: string // 描述
    url: string // 跳转地址
}

const TitleShell: React.FC<Props> = (props) => {
    const { title, text, url } = props
    // const navigate = useNavigate()
    // 当前路由路径
    const { pathname } = useLocation()
    // 获取驾校ID
    const id = useMemo(() => {
        const arr = pathname?.split('/').filter((item) => (item))
        const ydtCode = arr[arr.length - 1]?.split("?")[0]?.replace(/[^0-9]/g, '')
        return ydtCode ? ydtCode : '1707325546549014529'
    }, [pathname])

    // 跳转路由
    const goRoute = () => {
        if (url) {
            syncTrackLog({
                appId: "4",
                trackId: "jxtjp_gw_click",
                clientInfo: navigator.userAgent,
                tenantId: id,
                attrMap: {
                    attr2: url,
                    attr1: '查看更多'
                }
            })
            window.open(url)
            // navigate(url)
        }
    }

    return (
        <div className="title-shell">
            <div className="title-shell-title">{title}</div>
            <div className="title-shell-text-shell">
                <div className="title-shell-text-left">{text}</div>
                {
                    !!url &&
                    <div className="title-shell-text-right" onClick={goRoute}>查看更多 <img src={rout_1} /></div>
                }
            </div>
        </div>
    )
}

export default TitleShell
