import { banner_img_4 } from '@/imgs'
import React, { useEffect, useState } from "react";
import { getProcess } from '@/api/signup'
import './style.less'



const SignupNotice: React.FC = () => {

    const [info, setInfo] = useState<any>({
        timeScrolls: []
    })

    useEffect(() => {
        getProcess().then((res: any) => {
            setInfo(res?.data)
        })
    }, [])

    return (
        <div className="signup-page">
             <div className="signup-page-bg"> <img src={banner_img_4} alt="" /></div>
            <div className="signup-page-box">
                <div className="signup-title">报名须知</div>
                <div className="signUp-notice">
                    <div className="notice-title">报名前</div>
                    <div className="notice-content">
                        <ul style={{paddingLeft: 0}}>
                            <li>1、申请小型汽车、小型自动挡汽车、轻便摩托车准驾车型的，在 <b>18周岁以上，70周岁以下</b>。</li>
                            <li style={{ paddingLeft: '36px', textIndent: '-36px' }}>
                                2、申请低速载货汽车、三轮汽车、普通三轮摩托车、普通二轮模特车或者轮式自行机械车准驾车型的，<b>在18周岁以上，60周岁以下</b> 。
                            </li>
                            <li>
                                3、申请城市公交车、中型客车、大型货车、无轨电车或者有轨电车准驾车型的，<b>在21周岁以上，50周岁以下</b> 。
                            </li>
                            <li>
                                4、申请牵引车准驾车型的，<b>在24周岁以上，50周岁以下</b> 。
                            </li>
                            <li>5、申请大型客车准驾车型的，<b>在26周岁以上，50周岁以下。</b></li>
                        </ul>
                    </div>
                </div>
                <div className="signup-process">
                    <div className="notice-process-title">报名后</div>
                    <div className="process-item">
                        <div className="process-title">
                            <div className="process-title-name">报名</div>
                            <div className="step-circle">
                                <div className="step-square" />
                            </div>
                        </div>
                        <div className="process-steps">
                            {
                                info?.timeScrolls.slice(0, 2)?.map((item: any, index: number) => {
                                    return (
                                        <div className="step-item" key={index}>
                                            <div className="step-header">
                                                <div className={index !== 0 ? 'step-name gray second-sauqre' : "step-name"}>
                                                    {item.statusInfo}
                                                </div>
                                                <div className="step-date">{item.operateTime}</div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="process-item">
                        <div className="process-title">
                            <div className="process-title-name">科一</div>
                            <div className="step-circle">
                                <div className="step-square" />
                            </div>
                        </div>
                        <div className="process-steps">
                            {/* <div className="step-item">
                                <div className="step-header">
                                    <div className="step-name">科一练题</div>
                                </div>
                                <div className="step-notice">
                                    <span>下载驾校一点通APP</span>
                                    <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M6.58824 0L14 5.5L6.58824 11V8.21675H0V2.78325H6.58824V0Z" fill="#666666" />
                                    </svg>
                                    <span>顺序练习刷完</span>
                                    <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M6.58824 0L14 5.5L6.58824 11V8.21675H0V2.78325H6.58824V0Z" fill="#666666" />
                                    </svg>
                                    <span> 模拟考试(3天内模考3次90分以上)</span>
                                    <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M6.58824 0L14 5.5L6.58824 11V8.21675H0V2.78325H6.58824V0Z" fill="#666666" />
                                    </svg>
                                    <span>科一约考</span>
                                </div>
                            </div> */}
                            {
                                info?.timeScrolls?.slice(2, 5).map((item: any, index: number) => {
                                    return (
                                        <div className="step-item" key={index}>
                                            <div className="step-header">
                                                <div className={index !== 0 ? "step-name gray second-sauqre" : "step-name"}>{item?.statusInfo}</div>
                                                <div className="step-date">{item?.operateTime}</div>
                                            </div>
                                            {item?.remark &&
                                                <div className="step-notice">
                                                    {item?.remark}
                                                </div>
                                            }
                                        </div>
                                    )
                                })
                            }

                            {/* <div className="step-item">
                                <div className="step-header">
                                    <div className="step-name gray second-sauqre">科一提交约考</div>
                                    <div className="step-date">预计2023-09-26</div>
                                </div>
                                <div className="step-notice">
                                    <span>档案在122受理成功后可提交约考</span>
                                </div>
                            </div>
                            <div className="step-item">
                                <div className="step-header">
                                    <div className="step-name gray second-sauqre">科一考试</div>
                                    <div className="step-date">预计2023-10-07</div>
                                </div>
                                <div className="step-notice">
                                    <span>90分及格</span>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="process-item">
                        <div className="process-title">
                            <div className="process-title-name">科二</div>
                            <div className="step-circle">
                                <div className="step-square" />
                            </div>
                        </div>
                        <div className="process-steps">
                            {
                                info?.timeScrolls?.slice(5, 8).map((item: any, index: number) => {
                                    return (
                                        <div className="step-item" key={index}>
                                            <div className="step-header">
                                                <div className={index !== 0 ? "step-name gray second-sauqre" : "step-name"}>{item?.statusInfo}</div>
                                                <div className="step-date">{item?.operateTime}</div>
                                            </div>
                                            {item?.remark &&
                                                <div className="step-notice">
                                                    {item?.remark}
                                                </div>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="process-item">
                        <div className="process-title">
                            <div className="process-title-name">科三</div>
                            <div className="step-circle">
                                <div className="step-square" />
                            </div>
                        </div>
                        <div className="process-steps">
                            {
                                info?.timeScrolls?.slice(8, 11).map((item: any, index: number) => {
                                    return (
                                        <div className="step-item" key={index}>
                                            <div className="step-header">
                                                <div className={index !== 0 ? "step-name gray second-sauqre" : "step-name"}>{item?.statusInfo}</div>
                                                <div className="step-date">{item?.operateTime}</div>
                                            </div>
                                            {item?.remark &&
                                                <div className="step-notice">
                                                    {item?.remark}
                                                </div>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="process-item">
                        <div className="process-title">
                            <div className="process-title-name">科四</div>
                            <div className="step-circle">
                                <div className="step-square" />
                            </div>
                        </div>
                        <div className="process-steps">
                            {
                                info?.timeScrolls?.slice(11, 14).map((item: any, index: number) => {
                                    return (
                                        <div className="step-item" key={index}>
                                            <div className="step-header">
                                                <div className={index !== 0 ? "step-name gray second-sauqre" : "step-name"}>{item?.statusInfo}</div>
                                                <div className="step-date">{item?.operateTime}</div>
                                            </div>
                                            {item?.remark &&
                                                <div className="step-notice">
                                                    {item?.remark}
                                                </div>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="process-item">
                        <div className="process-title">
                            <div className="process-title-name">结业</div>
                            <div className="step-circle">
                                <div className="step-square" />
                            </div>
                        </div>
                        <div className="finally-step">
                            {
                                info?.timeScrolls?.slice(14, 15).map((item: any, index: number) => {
                                    return (
                                        <div className="step-item" key={index}>
                                            <div className="step-header">
                                                <div className="step-name">{item?.statusInfo}</div>
                                                <div className="step-date">{item?.operateTime}</div>
                                            </div>
                                            {item?.remark &&
                                                <div className="step-notice">
                                                    {item?.remark}
                                                </div>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default SignupNotice