import React from "react";
import TitleShell from '@/components/titleShell'
import { employ_img } from '@/imgs'
import './style.less'

const Employ: React.FC = () => {

    return (
        <div className="employ-shell">
            <TitleShell title="规范服务" text="保障每一个服务流程" url='' />
            <div className="employ-content">
                <img src={employ_img} alt="" />
            </div>
        </div>
    )
}

export default Employ
