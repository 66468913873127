import React, { useState, useEffect } from "react";
import TitleShell from '@/components/titleShell'
import { getFirstPage } from '@/api/home'
import './style.less'

interface Props {
    schoolId: string // 驾校ID
}

const StudyCar: React.FC<Props> = (props) => {
    const { schoolId } = props
    // 高亮的
    const [activeIndex, setActiveIndex] = useState(0)
    const [data, setData] = useState<any>(null)

    useEffect(() => {
        getData()
    }, [schoolId])

    // 获取数据
    const getData = async () => {
        try {
            const res: any = await getFirstPage()
            setData(res?.data)
        } catch (e) {

        }
    }
    // 移入触发的事件
    const onmouseover = (index: number) => {
        setActiveIndex(index)
    }
    // 移出触发的事件
    const onmouseout = () => {
        setActiveIndex(0)
    }
    return (
        <div className="study-car-shell">
            <TitleShell title="学车流程" text="学车有规划 轻松拿驾照" url={`/signupNotice/${schoolId}`} />
            <div className="study-car-content">
                <div className="study-car-content-bg study-car-content-bg-left">
                    <div
                        className={`study-car-item study-car-item-bg1 ${activeIndex === 0 ? 'study-car-item-active' : ''}`}
                        onMouseOver={() => { onmouseover(0) }}
                        onMouseOut={() => onmouseout()}
                    >
                        <div className="study-car-item-title">报名</div>
                        <div className="study-car-content-top">
                            <div className="study-car-content-title">报名成功</div>
                            <div className="study-car-content-text">今天 {data?.applySuccessTimeMd}</div>
                        </div>
                        <div className="study-car-content-bottom">
                            <div className="study-car-content-title">交管受理成功</div>
                            <div className="study-car-content-text">预计 {data?.acceptSuccessTimeMd}</div>
                        </div>
                    </div>
                </div>
                <div className="study-car-content-bg study-car-content-bg-center">
                    <div
                        className={`study-car-item study-car-item-bg2 ${activeIndex === 1 ? 'study-car-item-active' : ''}`}
                        onMouseOver={() => { onmouseover(1) }}
                        onMouseOut={() => onmouseout()}
                    >
                        <div className="study-car-item-title">科一</div>
                        <div className="study-car-content-top">
                            <div className="study-car-content-title">科一提交约考</div>
                            <div className="study-car-content-text">预计 {data?.oneAppointmentMd}</div>
                        </div>
                        <div className="study-car-content-bottom">
                            <div className="study-car-content-title">科一考试</div>
                            <div className="study-car-content-text">预计 {data?.oneExamTimeMd}</div>
                        </div>
                    </div>
                </div>
                <div className="study-car-content-bg study-car-content-bg-center">
                    <div
                        className={`study-car-item study-car-item-bg2 ${activeIndex === 2 ? 'study-car-item-active' : ''}`}
                        onMouseOver={() => { onmouseover(2) }}
                        onMouseOut={() => onmouseout()}
                    >
                        <div className="study-car-item-title">科二</div>
                        <div className="study-car-content-top">
                            <div className="study-car-content-title">科二提交约考</div>
                            <div className="study-car-content-text">预计 {data?.twoAppointmentMd}</div>
                        </div>
                        <div className="study-car-content-bottom">
                            <div className="study-car-content-title">科二考试</div>
                            <div className="study-car-content-text">预计 {data?.twoExamTimeMd}</div>
                        </div>
                    </div>
                </div>
                <div className="study-car-content-bg study-car-content-bg-center">
                    <div
                        className={`study-car-item study-car-item-bg2 ${activeIndex === 3 ? 'study-car-item-active' : ''}`}
                        onMouseOver={() => { onmouseover(3) }}
                        onMouseOut={() => onmouseout()}
                    >
                        <div className="study-car-item-title">科三</div>
                        <div className="study-car-content-top">
                            <div className="study-car-content-title">科三提交约考</div>
                            <div className="study-car-content-text">预计 {data?.threeAppointmentMd}</div>
                        </div>
                        <div className="study-car-content-bottom">
                            <div className="study-car-content-title">科三考试</div>
                            <div className="study-car-content-text">预计 {data?.threeExamTimeMd}</div>
                        </div>
                    </div>
                </div>
                <div className="study-car-content-bg study-car-content-bg-right">
                    <div
                        className={`study-car-item study-car-item-bg3 ${activeIndex === 4 ? 'study-car-item-active' : ''}`}
                        onMouseOver={() => { onmouseover(4) }}
                        onMouseOut={() => onmouseout()}
                    >
                        <div className="study-car-item-title">科四+拿证</div>
                        <div className="study-car-content-top">
                            <div className="study-car-content-title">科四提交约考</div>
                            <div className="study-car-content-text">预计 {data?.fourAppointmentMd}</div>
                        </div>
                        <div className="study-car-content-bottom">
                            <div className="study-car-content-title">科四考试+拿证</div>
                            <div className="study-car-content-text">预计 {data?.fourExamTimeMd}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StudyCar
