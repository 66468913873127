import React, { useState, useEffect, useMemo, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swiper from 'swiper';
import { Breadcrumb } from 'antd'
import { training_ground_img } from '@/imgs'
import { address_1, license, mobile_phone } from '@/utils/base64'
import { LoadBaiduMapScript } from '@/utils'
import { getRegionDetail, syncTrackLog } from '@/api/home'
import { openZhiChi } from '@/utils'
import EmptyList from '@/components/emptyList'
import './style.less'
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

declare const window: any

let map: any
// let polylineCircle = false
let customOverlay: any
let swiper2: any

const TrainCarDetail: React.FC = () => {
    const navigate = useNavigate()
    // 当前路由路径
    const { pathname, search } = useLocation()
    // 获取驾校ID
    const id = useMemo(() => {
        const arr = pathname?.split('/').filter((item) => (item))
        return arr[arr.length - 1]?.split("?")[0]?.replace(/[^0-9]/g, '')
    }, [pathname])
    // 训练场ID
    const detailId = useMemo(() => {
        const arr = search?.split('id=')
        return arr[arr.length - 1]
    }, [search])
    // 训练场数据
    const [data, setData] = useState<any>(null)
    // 当前页
    const [, setActiveIndex] = useState(1)
    const activeIndex = useRef<any>(1)

    useEffect(() => {
        LoadBaiduMapScript().then(() => {
            if (data) {
                console.log('======>开始初始化了')
                // polylineCircle = true
                setTimeout(() => {
                    console.log('======>才开始')
                    initMap()
                }, 500)
            }
        })
        return () => {
            map = null
            // polylineCircle = false
        }
    }, [data])

    // 获取列表数据
    useEffect(() => {
        if (detailId) {
            getData()
        }
    }, [detailId])

    useEffect(() => {
        setTimeout(() => {
            initSwiper()
        }, 300)
    }, [data])

    const getData = async () => {
        try {
            const res: any = await getRegionDetail({ id: detailId })
            setData(res?.data)
        } catch (e) {

        }
    }

    // 下一个训练场
    const goNext = () => {
        syncTrackLog({
            appId: "4",
            trackId: "jxtjp_gw_click",
            tenantId: id,
            clientInfo: navigator.userAgent,
            attrMap: {
                attr2: `${pathname}?id=${data?.nextDataId}`,
                attr1: '练车场地详情-下一个场地'
            }
        })
        window.scrollTo(0, 0)
        swiper2.slideTo(0, 100)
        navigate(`${pathname}?id=${data?.nextDataId}`)
    }
    // 初始化地图
    const initMap = () => {
        if (data && window.BMapGL?.Map) {
            try {
                map = new window.BMapGL.Map('train-car-map')
                let point = new window.BMapGL.Point(data?.longitude, data?.latitude)
                // 创建自定义覆盖物
                customOverlay = new window.BMapGL.CustomOverlay(createDOM, {
                    point: new window.BMapGL.Point(data?.longitude, data?.latitude),
                    opacity: 0.5,
                    offsetY: -44,
                });
                map.centerAndZoom(point, 18)
                map.addOverlay(customOverlay);
                map.enableScrollWheelZoom();
            } catch (e) {
                console.log('地图错误了====>', e)
            }
            
        }
    }

    const createDOM = () => {
        var div = document.createElement('div');
        div.style.zIndex = '99999';
        div.style.backgroundColor = '#E9623E';
        div.style.color = '#fff';
        div.style.height = '58px';
        div.style.padding = '0 20px';
        div.style.lineHeight = '58px';
        div.style.whiteSpace = 'nowrap';
        div.style.borderRadius = '10px';

        var title = document.createElement('div');
        title.style.display = 'block';
        title.style.fontSize = '16px';
        title.style.fontWeight = '600';
        title.style.fontFamily = 'PingFang SC';
        div.appendChild(title);
        title.appendChild(document.createTextNode(data?.name));


        var arrow = document.createElement('div');
        arrow.style.position = 'absolute';
        arrow.style.top = '58px';
        arrow.style.left = '43%';
        arrow.style.width = '0';
        arrow.style.height = '0';
        arrow.style.borderColor = '#E9623E transparent transparent transparent';
        arrow.style.borderStyle = 'solid';
        arrow.style.borderWidth = '10px';
        arrow.style.overflow = 'hidden';
        div.appendChild(arrow);
        return div;
    }

    // 创建tip
    const mapTip = () => {
        let point = new window.BMapGL.Point(data?.longitude, data?.latitude)
        map.centerAndZoom(point, 15)
        customOverlay.setPoint(new window.BMapGL.Point(data?.longitude, data?.latitude));
        // map.disableDragging()
        // let marker = new window.BMapGL.Marker(point);  // 创建标注
        // map.addOverlay(marker);
    }

    // 轮播图
    const goSilid = (index: number) => {
        swiper2.slideTo(index, 100)
        activeIndex.current = index + 1
        setActiveIndex(index + 1)
        const dom: any = document.querySelector('.train-my-Swiper .swiper-wrapper')
        if (index >= 2 && data?.jxCdPicUrl?.length > 3) {
            dom.style.marginTop = (-160 * (index - 1)) + 'px'
        } else {
            dom.style.marginTop = '0px'
        }
    }
    const initSwiper = () => {
        activeIndex.current = 1
        const dom: any = document.querySelector('.train-my-Swiper .swiper-wrapper')
        dom.style.marginTop = '0px'
        swiper2 = new Swiper(".train-mySwiper2", {
            spaceBetween: 10,
            noSwiping: true,
            // on: {
            //     slideChange: function (a: any) {
            //         // swiper.slideTo(a?.activeIndex, 100)
            //         activeIndex.current = a?.activeIndex + 1
            //         setActiveIndex(a?.activeIndex + 1)
            //         // const dom: any = document.querySelector('.train-my-Swiper')
            //         // if (a?.activeIndex + 1 !== 1) {
            //         //     dom.style.marginTop = '160px'
            //         // } else {
            //         //     dom.style.marginTop = '0px'
            //         // }
            //         // console.log(a?.activeIndex, '========>')
            //     },
            // },
        });
        swiper2.updateSize()
        swiper2.updateSlides()
        swiper2.slideTo(0, 100)
    }

    // 立即质询
    const goConsult = () => {
        syncTrackLog({
            appId: "4",
            trackId: "jxtjp_gw_click",
            tenantId: id,
            clientInfo: navigator.userAgent,
            attrMap: {
                attr2: pathname,
                attr1: '练车场地详情-立即咨询'
            }
        })
        openZhiChi()
    }


    return (
        <div className="train-car-detail">
            <div className="train-car-detail-top">
                <div className="train-detail-top-content">
                    <Breadcrumb
                        items={[
                            {
                                title: <div
                                    className="breadcrumb-btn"
                                    onClick={() => {
                                        window.scrollTo(0, 0)
                                        navigate(`/home/${id}`)
                                    }}>首页</div>,
                            },
                            {
                                type: 'separator',
                                separator: <span>//</span>,
                            },
                            {
                                title: <div
                                    className="breadcrumb-btn"
                                    onClick={() => {
                                        window.scrollTo(0, 0)
                                        navigate(`/trainCar/${id}`)
                                    }}>练车场地</div>,
                            },
                            {
                                type: 'separator',
                                separator: <span>//</span>,
                            },
                            {
                                title: data?.name,
                            },
                        ]}
                        separator=''
                    />
                </div>
            </div>
            <div className="train-car-detail-content">
                <div className="train-car-detail-shell">
                    <div className="train-car-detail-info">
                        <div className="train-detail-info-img"><img src={data?.jxCdPicUrl?.length ? data?.jxCdPicUrl[0] + '?w=351&h=197' : training_ground_img} alt="" /></div>
                        <div className="train-detail-info-right">
                            <div className="info-right-top">
                                <div className="info-top-content">
                                    <div className="info-top-title">
                                        {/* <span className="info-top-title"></span> */}
                                        {data?.name}
                                        <div className="course-name">{data?.courseName}</div>
                                    </div>
                                    <div className="info-top-vehicle">
                                        <img src={license} alt="" />
                                        <div className="info-top-vehicle-text">{data?.vehicleType?.split(',')?.join('、')}</div>
                                    </div>
                                </div>
                                <div className="info-right-top-btn" onClick={goConsult}>报名咨询</div>
                            </div>
                            <div className="info-contacts">
                                <img src={mobile_phone} alt="" />
                                <div className="info-contacts-content">
                                    <span className="info-contacts-text">{data?.contacts}</span>
                                    <span className="info-contacts-phone">{data?.phone}</span>
                                </div>
                            </div>
                            <div className="info-address">
                                <img src={address_1} alt="" />
                                <div className="info-address-text">{data?.address}</div>
                            </div>
                        </div>
                    </div>
                    <div className="train-car-synopsis">
                        <div className="synopsis-title">练车场地介绍</div>
                        <div className="synopsis-content">
                            {data?.regionDesc}
                        </div>
                    </div>
                    {
                        !!data?.jxCdPicUrl?.length ?
                            <div className="train-car-imgs">
                                <div className="swiper train-mySwiper2">
                                    <div className="swiper-wrapper">
                                        {
                                            data?.jxCdPicUrl?.map((item: string, index: number) => {
                                                return (
                                                    <div
                                                        className="swiper-slide my-Swiper-slide swiper-no-swiping"
                                                        key={index}
                                                    >
                                                        <img src={item + '?w=1369&h=769'} />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div className="train-my-swiper">
                                    <div className="swiper train-my-Swiper">
                                        <div className="swiper-wrapper" style={{ minHeight: '520px' }}>
                                            {
                                                data?.jxCdPicUrl?.map((item: string, index: number) => {
                                                    return (
                                                        <div
                                                            onClick={() => goSilid(index)}
                                                            className={activeIndex.current - 1 === index ? 'swiper-slide my-Swiper-slide my-swiper-slide-active' : 'swiper-slide my-Swiper-slide'}
                                                            key={index}
                                                        >
                                                            <img src={item + '?w=300&h=166'} />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="train-car-imgs-page">
                                    {activeIndex.current}/{data?.jxCdPicUrl?.length}
                                </div>
                            </div> :
                            <>
                                <EmptyList />
                            </>
                    }
                    <div className="train-car-map">
                        <div id='train-car-map'></div>
                    </div>
                </div>
            </div>
            <div className="train-car-detail-bottom">
                <div className="train-car-detail-bottom-content">
                    {
                        !!data?.nextDataId &&
                        <span
                            className="next-text"
                            onClick={goNext}
                        >下一个场地：{data?.nextDataName}</span>
                    }
                </div>
            </div>
        </div>
    )
}

export default TrainCarDetail