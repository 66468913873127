
// 导航路由
export const navList = [
    {
        path: "/home",
        name: '首页',
    },
    {
        path: "/trainCar",
        name: '练车场地',
    },
    {
        path: "/classBrief",
        name: '班型介绍',
    },
    {
        path: "/starCoach",
        name: '明星教练',
    },
    {
        path: "/signupNotice",
        name: '报名须知',
    },
    {
        path: "/tenantInfo",
        name: '驾校资讯',
    },
    {
        path: "/contactUs",
        name: '联系我们',
    }
]

export const imgs={
    activity:'https://img.58cdn.com.cn/dist/jxt/images/jxtschool/jxt-11-activity-s.png?v=8',
    close:'https://img.58cdn.com.cn/dist/jxt/images/jxtschool/jxt-activity-close.jpg?v=2',
   'show-activity':'https://img.58cdn.com.cn/dist/jxt/images/jxtschool/jxt-activity-discount-1.png?a=11',
   'jxt-activity-pc-btn':'https://img.58cdn.com.cn/dist/jxt/images/jxtschool/jxt-activity-pc-btn.png'

}

// 地址切换数据
export const addressList = process.env.REACT_APP_ENV === 'production' ?
[
    {
        name: '佛山市',
        id: '1707325546549014529',
    },
    {
        name: '石家庄市',
        id: '22144',
    },
] :
[
    {
        name: '株洲市',
        id: '1390632768383229952',
    },
    {
        name: '北京市',
        id: '1426060676178128896'
    },
]

