import React, { useEffect, useState, useMemo } from 'react'
import { Layout, Popover, message } from 'antd'
import { CheckCircleFilled } from '@ant-design/icons'
import { Route, Routes, useNavigate, useLocation, Navigate } from "react-router-dom";
import { getSearch, syncTrackLog, addClue, getCoachList } from '@/api/home'
import routers from "@/routers";
import { initZhiChi, getApplicationMobile, getToken } from '@/utils'
import { right_phone1, right_robot, right_scrolltop, right_phone_2, right_robot_1, right_scrolltop_1, footer_wechat4 } from '@/imgs'
import { AppContext } from '@/models'
import MyHeader from './header'
import { imgs } from './base'
import MyFooter from './footer'
import Login from './login'

import './style.less'

const { Header, Footer, Content } = Layout;

const MyLayout: React.FC = () => {
    const navigate = useNavigate()
    // 当前路由路径
    const { pathname, ...roters } = useLocation()
    // 获取驾校ID
    const id = useMemo(() => {
        const arr = pathname?.split('/').filter((item) => (item))
        const ydtCode = arr[arr.length - 1]?.split("?")[0]?.replace(/[^0-9]/g, '')
        return ydtCode ? ydtCode : '1707325546549014529'
    }, [pathname])
    // 划入的btn
    const [btnActive, setBtnActive] = useState(0)
    // 驾校信息
    const [schInfo, setSchInfo] = useState<any>(null)
    //手机号
    const [phone, setPhone] = useState<string>('')
    //展示活动
    const [showActicity, setShowActicity] = useState<boolean>(true)
    // 明星教练数据
    const [data, setData] = useState(true)
    // 登陆页面的展示
    const [showLogin, setShowLogin] = useState(false)
    // 优惠领取成功提示页
    const [isPreferential, setIsPreferential] = useState(false)


    // 初始化智齿
    useEffect(() => {
        // // 进来默认首页
        // if (id) {
        //     !pathname.includes('/home') && navigate("/home" + `/${id}`)
        // } else {
        //     navigate("/nothave")
        // }
        if (!id) {
            navigate("/nothave")
        }
        initZhiChi()
        onScroll()
        let userAgentInfo = navigator.userAgent;
        let Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
        let isPC = true;
        for (let i = 0; i < Agents.length; i++) {
            if (userAgentInfo.indexOf(Agents[i]) > 0) {
                isPC = false; break;
            }
        }

        if (!isPC) {
            window.location.href = `https://m.xueche.com/h5/#/schoolWeb?ydtCode=${id}`
        }
    }, [])

    useEffect(() => {
        getData()
        if (id) {
            localStorage.setItem('id', id)
            getSchWeb()
        } else {
            localStorage.removeItem('id')
        }
    }, [id])

    useEffect(() => {
        console.log()
        // 登陆失效跳转后的处理
        if (roters?.state?.login) {
            navigate(`/home/${id}`)
            setShowLogin(true)
        }
    }, [roters])

    // 绑定滚动事件
    const onScroll = () => {
        window.addEventListener('scroll', () => {
            const top = window.scrollY
            const dom: any = document.querySelector('.fiexd-container-toTop')
            if (dom) {
                if (top > 560) {
                    // console.log(dom, '=====>')
                    dom.className = 'fiexd-container-toTop'
                } else {
                    dom.className = 'fiexd-container-toTop fiexd-container-toTop-none'
                }
            }
            // console.log(top, '======>')
        })
    }

    // 返回顶部
    const goTop = () => {
        syncTrackLog({
            appId: "4",
            trackId: "jxtjp_gw_click",
            clientInfo: navigator.userAgent,
            tenantId: id,
            attrMap: {
                attr2: pathname,
                attr1: '返回顶部'
            }
        })
        window.scrollTo(
            {
                left: 0, // 等同于x
                top: 0, // 等同于y
                behavior: "smooth", // 设置滚动动画效果为smooth，即平滑滚动
            }
        )
    }

    // 右侧移入事件
    const onmouseover = (index: number) => {
        setBtnActive(index)
    }
    // 右侧移出事件
    const onmouseout = () => {
        setBtnActive(0)
    }
    // 获取首页驾校信息
    const getSchWeb = async () => {
        try {
            const res: any = await getSearch()
            if (res.code !== 0 && res.code !== 200) {
                navigate("/nothave")
            } else {
                setSchInfo(res?.data)
            }

        } catch (e) {

        }
    }
    //领取优惠
    const getGift = async () => {
        const phoneRegEx = /^1[3-9]\d{9}$/;
        if (!phoneRegEx.test(phone)) {
            message.error('请输入正确的手机号')
            return
        }
        let res: any = await addClue({ type: 1, cellPhone: phone })
        console.log('-----', res)
        if (res.code !== 0) {
            message.error(res.message)
        } else {
            // message.success(res.message)
            // message.success('优惠领取成功，请保持手机通畅，稍后会专人联系您~')
            setShowActicity(false)
            setIsPreferential(true)
        }
    }

    // 获取数据
    const getData = async () => {
        try {
            const res: any = await getCoachList({ isHome: true, pageIndex: 1, pageSize: 6 })
            setData(!!res?.data?.pagination.totalRows)
        } catch (e) {

        }
    }

    // 点击订单按钮
    const orderBtnFn = () => {
        console.log(getToken('openCookieid'), '======>')
        const openCookieId = getToken('openCookieid')
        if (openCookieId) {
            syncTrackLog({
                appId: "4",
                trackId: "jxtjp_gw_click",
                clientInfo: navigator.userAgent,
                tenantId: id,
                attrMap: {
                    attr2: pathname,
                    attr1: '我的订单'
                }
            })
            navigate(`/myorder/${id}`)
        } else {
            setShowLogin(true)
        }
    }

    return (
        <AppContext.Provider value={{}}>
            <Layout id='layout' style={{ position: 'relative' }}>
                <Header className='header-style'>
                    <div className='home-header'>
                        <MyHeader schInfo={schInfo} isCoach={data} orderBtnFn={orderBtnFn} />
                    </div>
                </Header>
                <Content className='content-style'>
                    <Routes>
                        {
                            routers?.map((item, index) => {
                                return (
                                    <Route key={index} path={item.path} element={item.element} />
                                )
                            })
                        }
                        <Route path={'/*'} element={<Navigate replace to={`/home/${id}`} />} />
                    </Routes>
                </Content>
                <Footer className='footer-style'>
                    <MyFooter schInfo={schInfo} />
                </Footer>
                <div className='fiexd-container'>
                    <div className='fiexd-container-top'>
                        <div className='fiexd-container-top-item'>
                            <img className='fiexd-wxcode-img' src={schInfo?.wxQrCode} />
                            <div className='fiexd-wx-text'>用微信扫我报名</div>
                        </div>
                        <Popover
                            placement="left"
                            content={<div className='fiexd-application'>
                                <div className='fiexd-application-title'>58驾培集团学车热线</div>
                                <div className='fiexd-application-phone'>
                                    {
                                        // getApplicationMobile('18902812402')
                                        getApplicationMobile(schInfo?.contactPhone || '')
                                    }
                                </div>
                            </div>}
                            arrow={false}
                            overlayInnerStyle={{ marginRight: '-1px', boxShadow: '0px 2px 18px 0px rgba(0, 0, 0, 0.07)' }}
                        >
                            <div
                                className='fiexd-container-top-item fiexd-container-top-wraaper'
                                onMouseOver={() => { onmouseover(1) }}
                                onMouseOut={() => onmouseout()}
                            >
                                <img src={btnActive === 1 ? right_phone_2 : right_phone1} />
                                <div className='fiexd-container-text fiexd-container-text-border'>电话咨询</div>
                            </div>
                        </Popover>
                        <div
                            id='zhi-custom-btn'
                            className='zhiCustomBtn fiexd-container-top-item fiexd-container-top-wraaper'
                            onMouseOver={() => { onmouseover(2) }}
                            onMouseOut={() => onmouseout()}
                        >
                            <img src={btnActive === 2 ? right_robot_1 : right_robot} />
                            <div
                                className='fiexd-container-text'
                                onClick={() => syncTrackLog({
                                    appId: "4",
                                    trackId: "jxtjp_gw_click",
                                    clientInfo: navigator.userAgent,
                                    tenantId: id,
                                    attrMap: {
                                        attr2: pathname,
                                        attr1: '右侧立即咨询'
                                    }
                                })}
                            >在线咨询</div>
                        </div>
                    </div>
                    <div
                        className='fiexd-container-toTop fiexd-container-toTop-none'
                        onClick={goTop}
                        onMouseOver={() => { onmouseover(3) }}
                        onMouseOut={() => onmouseout()}
                    >
                        <img src={btnActive === 3 ? right_scrolltop_1 : right_scrolltop} />
                        <div
                            className='fiexd-container-toTop-text'
                        >返回顶部</div>
                    </div>
                </div>

                <div className='jxt-activity' style={{ left: showActicity ? 0 : '100%' }}>
                    <div className='activity-content'>
                        <div className='activity-img'>
                            <img src={imgs.activity} alt="" />
                        </div>
                        <div className='input-phone'>
                            <div className='input-phone-box'>
                                <div className='input-div'>
                                    <input type="text" className='phone-input' maxLength={11} placeholder='请输入手机号' value={phone} onChange={e => { setPhone(e.target.value) }} />
                                </div>
                                <div className='activity-pick-btn' onClick={getGift}>
                                    <img src={imgs['jxt-activity-pc-btn']} />
                                </div>
                            </div>
                            <div className='login-protect-text'>领取优惠即同意<a href='https://j1.58cdn.com.cn/dist/jxt/agreement/jxt-empower.html' target="_blank">《用户使用协议》</a></div>
                        </div>

                        <div className='jx-acticity-close' onClick={() => { setShowActicity(false) }}>
                            <img src={imgs.close} alt="" />
                        </div>
                    </div>
                </div>
                {
                    !showActicity &&
                    <div className='show-jxt-activity' onClick={() => { setShowActicity(true) }}>
                        <div>
                            <img src={imgs['show-activity']} alt="" />
                        </div>
                    </div>
                }
                {
                    showLogin &&
                    <Login setShowLogin={setShowLogin} />
                }
                {isPreferential &&
                    <div className='preferential-bg'>
                        <div className='preferential-wrraper'>
                            <CheckCircleFilled className='preferential-icon' />
                            <div className='preferential-title'>优惠领取成功！</div>
                            <div className='preferential-text'>请保持手机通畅，稍后会有专人联系您～</div>
                            <div className='preferential-btn' onClick={() => setIsPreferential(false)}>确定</div>
                        </div>
                    </div>
                }
            </Layout>
        </AppContext.Provider>
    )
}

export default MyLayout
