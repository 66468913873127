import request from '@/utils/request'


// 首页获取驾校信息
export function getSearch () {
    return request.postJson('/api/schWeb/firstPage/search', {})
}

// 训练场列表接口
export function getList (data = {}) {
    return request.postJson('/api/schWeb/region/list', data)
}

// 教练列表接口
export function getCoachList (data = {}) {
    return request.postJson('/api/schWeb/coach/list', data)
}

// 对外班制列表接口
export function getOutList (data = {}) {
    return request.postJson('/api/schWeb/class/out/list', data)
}

// 报名成功后学车规划展示
export function getCalendar (data = {}) {
    return request.postJson('/api/schWeb/process/calendar', data)
}

// 首页报名成功后学车规划展示
export function getFirstPage (data = {}) {
    return request.postJson('/api/schWeb/process/firstPage', data)
}

// 训练场详情
export function getRegionDetail (data = {}) {
    return request.postJson('/api/schWeb/region/detail', data)
}

// 明星教练详情
export function getCoachDetail (data = {}) {
    return request.postJson('/api/schWeb/coach/coachDetail', data)
}

// 明星教练评价
export function getCoachDpList (data = {}) {
    return request.postJson('/api/schWeb/coach/dpList', data)
}

// 埋点
export function syncTrackLog (data = {}) {
    return request.postJson('/track/log/syncTrackLog', data, 'JXTCOMMON_API')
}

// 录入线索
export function addClue (data = {}) {
    return request.postJson('/api/schWeb/clue/add', data)
}

// 训练场详情
export function getClassDetail (data = {}) {
    return request.postJson('/api/schWeb/class/out/detail', data)
}

//发送短信验证码
export function inviteMobileMsgCode(data: any) {
    return request.postJson(`/api/schWeb/code`, data)
}

// 登陆接口
export function schWebLogin(data: any) {
    return request.postJson('/api/schWeb/login', data)
}

// 获取订单信息
export function getOrderList(data: any) {
    return request.postJson('/api/schWeb/personal/order/list', data)
}

