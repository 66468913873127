import React, { useMemo } from 'react'
import { useLocation } from "react-router-dom";
import Banner from './components/banner'
import StudyCar from './components/studyCar'
import TraincCar from './components/traincCar'
import Coach from './components/coach'
import ClassIntro from './components/classIntro'
import Wisdom from './components/wisdom'
import Employ from './components/employ'
import './style.less'


const Home: React.FC = () => {
    // 当前路由路径
    const { pathname } = useLocation()
    // 获取驾校ID
    const id = useMemo(() => {
        const arr = pathname?.split('/').filter((item) => (item))
        return arr[arr.length - 1]?.split("?")[0]?.replace(/[^0-9]/g, '')
    }, [pathname])

    return (
        <>
            <Banner />
            <div className='home-synopsis'>
                <div>
                    <div className='home-synopsis-title'>58驾培集团介绍</div>
                    <div className='home-synopsis-text'>
                        <div>58驾培是58集团旗下的驾驶培训服务品牌，助您高效通过驾驶考试。</div>
                        <div>理论学习方面，我们通过自有在线学习平台“驾校一点通”，帮您轻松备考，并根据您的练习情况提供复习建议，有效提高考试通过率。</div>
                        <div>实操训练方面，我们采用线上预约、线下教学的模式，提供一对一指导。实操训练前，您可以在智能模拟器上进行全真模拟练习，减轻学习压力。实车训练中，通过智能机器人系统进行规范教学，提高教学质量。</div>
                        <div>我们重视学员的评价，通过学员对教练的评价系统，我们更好地了解教练的教学质量，确保教学过程规范、公正。另外，我们还提供一对一的管家服务，主动帮您解决各种问题。选择58驾培，就是选择专业、高效、贴心的驾驶学习体验。</div>
                        {/* 58驾培是58集团旗下的一家覆盖全国连锁驾校。解决学员科一~科四全部科目学习，科一、四用”<span className='home-synopsis-text-cu'>驾校一点通</span>”APP刷题练习（<span className='home-synopsis-text-cu'>行业第一家小车科一1600题</span>）；科二、三线上约课，<span className='home-synopsis-text-cu'>练车前进行全实景模拟练习</span>，缓解学员学车压力（<span className='home-synopsis-text-cu'>训练场/考场1:1建模还原</span>），练实车结合“智能机器人”精准智能评判，规范化标准教学，提高学车质量；学员练车接通评价系统，学员对教练的每1次教学评价驾校都重点关注；更有智能班主任1对1全程守护学员学车的每个环节，让学员学车体验更好更轻松的一家驾校。 */}
                    </div>
                </div>
            </div>
            <div className='home-center'>
                <TraincCar schoolId={id} />
                <ClassIntro schoolId={id} />
                <Coach schoolId={id} />
            </div>
            <Wisdom />
            <div className='home-center'>
                <StudyCar schoolId={id} />
                <Employ />
            </div>
        </>
    )
}

export default Home
