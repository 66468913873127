import React, { useMemo, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { banner_img_2 } from '@/imgs'
import { getList } from '@/api/trainCar'
import { syncTrackLog } from '@/api/home'
import TrainingGround from '@/components/trainingGround'
import MyPagination from '@/components/pagination'
import EmptyList from '@/components/emptyList'
import './style.less'

const TrainCar: React.FC = () => {
    const navigate = useNavigate()
    // 当前路由路径
    const { pathname } = useLocation()
    // 获取驾校ID
    const id = useMemo(() => {
        const arr = pathname?.split('/').filter((item) => (item))
        return arr[arr.length - 1]?.split("?")[0]?.replace(/[^0-9]/g, '')
    }, [pathname])

    // 训练场数据
    const [data, setData] = useState({
        list: [],
        pagination: {
            pageIndex: 1,
            pageSize: 8,
            totalPages: 1,
            totalRows: 2,
        }
    })

    // 获取列表数据
    useEffect(() => {
        getData({ pageIndex: 1 })
    }, [id])

    const getData = async (page: any) => {
        try {
            const res: any = await getList({ isHome: false, schoolId: id, ...page, pageSize: 8 })
            setData(res?.data)
        } catch (e) {

        }
    }

    // 改变分页
    const changePage = (pageIndex: number) => {
        syncTrackLog({
            appId: "4",
            trackId: "jxtjp_gw_click",
            tenantId: id,
            clientInfo: navigator.userAgent,
            attrMap: {
                attr2: pathname,
                attr1: '练车场地-分页按钮'
            }
        })
        getData({ pageIndex: pageIndex })
    }

    // 跳转详情
    const goDetail = (value: any) => {
        syncTrackLog({
            appId: "4",
            trackId: "jxtjp_gw_click",
            tenantId: id,
            clientInfo: navigator.userAgent,
            attrMap: {
                attr2: `/trainCarDetail/${id}?id=${value?.id}`,
                attr1: '练车场地-详情'
            }
        })
        window.scrollTo(0, 0)
        navigate(`/trainCarDetail/${id}?id=${value?.id}`)
    }

    return <div className="train-car-shell">
        <div className="train-car-bg"> <img src={banner_img_2} alt="" /></div>
        <div className="train-car-center">
            <div className="train-car-title">练车场地</div>
            <div className="train-car-warrper">
                {
                    data?.list?.map((item, index) => (
                        <div
                            key={index}
                            onClick={() => (goDetail(item))}
                            style={{ marginRight: ((index + 1) % 4) === 0 ? '' : '20px', marginTop: '22px', cursor: 'pointer' }}
                        >
                            <TrainingGround data={item} />
                        </div>
                    ))
                }
                {!data?.pagination?.totalRows &&
                    <EmptyList />
                }
            </div>
            <div className="train-car-page">
                <MyPagination
                     total={data?.pagination?.totalRows}
                     pageSize={data?.pagination?.pageSize}
                     pageIndex={data?.pagination?.pageIndex}
                    changePage={changePage}
                />
            </div>
        </div>
    </div>
}

export default TrainCar