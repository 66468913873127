import React from "react";
import { user_head_default } from '@/imgs'
import { coach_dingwei } from '@/utils/base64'
import "./style.less"

interface Props {
    data: any // 评价数据
}

const IntroduceItem: React.FC<Props> = (props) => {
    const { data } = props
    return (
        <div className="introduce-item">
            <div className="introduce-shell">
                <div className="introduce-user-img">
                    <img src={data?.anonymous ? user_head_default : (data?.headPicUrl ? data?.headPicUrl + '?w=60&h=60' : user_head_default)} alt="" />
                </div>
                <div className="introduce-content">
                    <div className="introduce-user-top">
                        <div className="introduce-user-name">{data?.anonymous ? '匿名用户' : data?.stuName}</div>
                        <div className="introduce-user-time">{data.pcDpTime}</div>
                    </div>
                    <div className="introduce-user-info">
                        <div className="coach-dingwei-img"><img src={coach_dingwei} alt="" /></div>
                        <div className="coach-info-evaluate-text">
                            服务<span className="coach-info-text-number" style={{ marginRight: '10px' }}>{data?.starts}</span>
                        </div>
                        <div className="info-address">
                            {
                                data?.dpInfo?.map((item: string, index: number) => {
                                    return (
                                        <div className="coach-tab-item" style={{ marginLeft: index !== 0 ? '6px' : '' }} key={index}>{item}</div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="introduce-text">{data?.content}</div>
                </div>
            </div>
        </div>
    )
}

export default IntroduceItem
