import { tenant_info_img1, tenant_info_img2, tenant_info_img3 } from '@/imgs'

export const navs=[
    {
        name:'驾校新闻',
        key:'0',
        img: tenant_info_img3,
    },
    {
        name:'驾校公告',
        key:'1',
        img: tenant_info_img2,
    },
    {
        name:'常见问题',
        key:'2',
        img: tenant_info_img1
    },
]

export const imgUrls: any = {
    '0': tenant_info_img3,
    '1': tenant_info_img2,
    '2': tenant_info_img1
}