import request from '@/utils/request'


//新闻列表
export function getNews (data={}) {
    return request.postJson('/api/schWeb/combination/search', data)
}

//新闻详情
export function  getNewDetail(data={}) {
    return request.postJson('/api/schWeb/combination/detail', data)
}